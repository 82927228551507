import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/uikit-wrappers';
import OpenHouseCard from './OpenHouseCard';

/**
 * @typedef OpenHousesProps
 * @property {string} openHouseText Legacy listing.OpenHouseText string
 * @property {import('./types').OpenHouse[]} openHouses Array of OpenHouse Objects
 */

/**
 * Render either the legacy OpenHouseText UI or the new UI for the OpenHouses objects.
 *
 * @param {OpenHousesProps} props
 * @returns {JSX.Element}
 */
export default function OpenHouses(props) {
  const { openHouses, openHouseText } = props;

  let Component = null;

  if (openHouses && openHouses.length) {
    Component = (
      <>
        {openHouses.map((oh) => (
          <OpenHouseCard
            key={oh._ID}
            startTime={oh.StartDateText}
            endTime={oh.EndDateText}
            description={oh.Description}
            url={oh.VirtualOpenHouseURL}
            isLive={oh.isLive}
          />
        ))}
      </>
    );
  } else if (openHouseText) {
    Component = (
      <Alert type="warning">
        <span itemProp="event" dangerouslySetInnerHTML={{ __html: openHouseText }} />
      </Alert>
    );
  }

  return Component;
}

OpenHouses.propTypes = {
  openHouses: PropTypes.arrayOf(
    PropTypes.shape({
      _ID: PropTypes.number,
      VirtualOpenHouseURL: PropTypes.url,
      StartDateText: PropTypes.string,
      EndDateText: PropTypes.string,
      Description: PropTypes.string,
      HasRefreshments: PropTypes.bool,
      isLive: PropTypes.bool,
    })
  ),
  openHouseText: PropTypes.string,
};
