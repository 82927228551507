import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Input } from 'coreComponents';
import emptyFunction from 'utility/emptyFunction';
import { strip, decorateClean } from 'utility/string';
import getCursorPos from 'utility/getCursorPos';
import FAIcon from 'components/core/FAIcon';

// TODO: At some point, remove the current PriceInput component being used in the SearchMenu
// in favor of this new component. Then rename this component to simply `PriceInput`.

class MobilePriceInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: this.valueToState(this.props.value),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: this.valueToState(nextProps.value),
      });
    }
  }

  setInputRef = (c) => {
    this.props.inputRef(c);
    this._input = c;
  };

  valueToState = (value) => (value !== this.props.defaultValue ? String(value) : '');

  handleChange = (e) => {
    const el = e.target;

    // The new value is straight from the input
    const value = el.value;

    const currentCaretPosition = Math.max(el.selectionStart, el.selectionEnd);
    const pos = getCursorPos(value, this.state.value, currentCaretPosition);

    // Because we decorate this string, we _always_ have to manage cursor position
    setTimeout(() => this._input.setSelectionRange(pos, pos));

    this.setState({
      value: strip(value),
    });
  };

  /**
   * Trigger a blur event on the input when Enter is pressed, which commits the
   * change to the input.
   * @param {KeyboardEvent} e
   */
  handleKeyDown = (e) => {
    this.props.onKeyDown(e);
    if (e.key === 'Enter') {
      e.target.blur();
      e.preventDefault();
    }
  };

  handleBlur = (e) => {
    this.props.onBlur(e);
    this.props.onPriceChange(this.state.value);
  };

  render() {
    return (
      <Input
        className={this.props.className}
        pattern="[0-9]*"
        inputMode="numeric"
        label={this.props.label}
        id={this.props.id}
        dataCY={this.props.dataCY}
        name={this.props.name}
        value={decorateClean(this.state.value)}
        tabIndex={this.props.tabIndex}
        title={this.props.placeholder}
        onChange={this.handleChange}
        onFocus={this.props.onFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        ref={this.setInputRef}
        grouped
        prefix={<FAIcon icon="dollar-sign" />}
        helperTextProps={this.props.helperTextProps}
      />
    );
  }
}

MobilePriceInput.displayName = 'MobilePriceInput';
MobilePriceInput.propTypes = {
  /** @type {(number|null) => void} */
  onPriceChange: PropTypes.func.isRequired,
  /** @type {number} Initial committed value */
  value: PropTypes.number,
  /** @type {number} A fallback value to represent empty */
  defaultValue: PropTypes.number,

  className: PropTypes.string,
  icon: PropTypes.element,
  id: PropTypes.string,
  dataCY: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,

  /** Props to pass through to Input and onto the InputHelperText component */
  helperTextProps: PropTypes.object,
};
MobilePriceInput.defaultProps = {
  defaultValue: null,
  inputRef: emptyFunction,
  onBlur: emptyFunction,
  onFocus: emptyFunction,
  onKeyDown: emptyFunction,
};

export default MobilePriceInput;
