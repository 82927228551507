/**
 * Render the block if the passed variable is not null and not undefined. (This
 * helper has been implemented in our gulp-packages repo using PHP's `isset()`.)
 * @param {*} x
 * @param {Object} options
 * @param {Function} options.fn
 * @param {Function} options.inverse
 */
export default function isDefined(x, options) {
  return x !== null && x !== undefined ? options.fn(this) : options.inverse(this);
}
