import React from 'react';
import PropTypes from 'prop-types';

import { Fieldset, FieldsetLegend, Input, Radio, TextArea } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import { MobilePriceInput } from 'components/Common/FormElements';
import RecaptchaV2 from 'components/Common/FormElements/RecaptchaV2';

import ContactFormDisclaimer from 'components/Common/ContactFormDisclaimer';
import { PROP_DETAILS } from 'cypress_constants';
import tryParseInt from 'utility/tryParseInt';

const fieldID = (fieldName) => `StartOfferForm__${fieldName}`;

const StartAnOfferFormFields = (props) => {
  const {
    fullName,
    email,
    phone,
    offerAmount,
    helpWithFinancing,
    hasViewedProperty,
    comments,
    onChange,
    errors,
  } = props;

  /**
   * Get the corresponding error object from our state.
   *
   * @note There are better ways of handling error state, but we'll refactor to consolidate
   * how we're handling forms soon hopefully anyway. We can implement a better error state
   * structure at that time.
   *
   * @param {string} fieldName
   * @returns {{type:string, message:string}}
   */
  const getError = (fieldName) => {
    if (errors && errors.length) {
      const fieldError = errors.find((err) => err.field === fieldName);

      if (fieldError) {
        return { type: fieldError.field, message: fieldError.message };
      }

      return null;
    }

    return null;
  };

  return (
    <Grid gutters resetVertical xs="full">
      <Cell md={6}>
        <Input
          label="Full Name"
          id={fieldID('fullName')}
          dataCY={PROP_DETAILS.SO_FULL_NAME}
          name={fieldID('fullName')}
          value={fullName.value}
          autoComplete="name"
          onChange={(e) => {
            onChange({
              field: 'fullName',
              value: e.target.value,
            });
          }}
          required
          error={getError('fullName')}
        />
      </Cell>
      {/* Email and phone fields */}
      <Cell md={6}>
        <Input
          label="Phone"
          id={fieldID('phone')}
          dataCY={PROP_DETAILS.SO_PHONE}
          data-parsley-phone
          type="tel"
          autoComplete="tel"
          name={fieldID('phone')}
          required
          value={phone.value}
          onChange={(e) => {
            onChange({
              field: 'phone',
              value: e.target.value,
            });
          }}
          error={getError('phone')}
        />
      </Cell>
      <Cell>
        <Input
          label="Email"
          id={fieldID('email')}
          dataCY={PROP_DETAILS.SO_EMAIL}
          name={fieldID('email')}
          type="email"
          autoComplete="email"
          required
          value={email.value}
          onChange={(e) => {
            onChange({
              field: 'email',
              value: e.target.value,
            });
          }}
          error={getError('email')}
        />
      </Cell>
      <Cell md={6}>
        <MobilePriceInput
          // This component takes a number...
          value={offerAmount.value}
          label="Offer Amount"
          id={fieldID('offerAmount')}
          dataCY={PROP_DETAILS.SO_OFFER_AMT}
          name={fieldID('offerAmount')}
          helperTextProps={{ children: '(Optional)' }}
          onPriceChange={(price) => {
            onChange({
              field: 'offerAmount',
              // ...but reports back a string, and we're maintaining a number in Redux.
              value: tryParseInt(price, offerAmount.value),
            });
          }}
        />
      </Cell>
      <Cell>
        <Fieldset className="mb-4">
          <FieldsetLegend className="mb-12">Do you need help with financing?</FieldsetLegend>
          <Grid gutters alignItems="center">
            {helpWithFinancing.options.map((v, i) => (
              <Cell
                id="helpWithFinancing-wrapper"
                key={`helpWithFinancing-${v}`}
                xs={12}
                sm={i === 2 ? 6 : 3}
              >
                <Radio
                  label={v}
                  id={`helpWithFinancing-${v}`}
                  dataCY={PROP_DETAILS.SO_FINANCE_HELP}
                  value={v}
                  onChange={() => {
                    onChange({
                      field: 'helpWithFinancing',
                      value: v,
                    });
                  }}
                  name="helpWithfinancing"
                  checked={v === helpWithFinancing.value}
                />
              </Cell>
            ))}
          </Grid>
        </Fieldset>
      </Cell>
      <Cell>
        <Fieldset className="mb-4">
          <FieldsetLegend className="mb-12">Have you seen this home in person?</FieldsetLegend>
          <Grid gutters alignItems="center">
            {hasViewedProperty.options.map((v, i) => (
              <Cell
                id="hasViewedProperty-wrapper"
                key={`hasViewedProperty-${v}`}
                xs={12}
                sm={i === 2 ? 6 : 3}
              >
                <Radio
                  label={v}
                  id={`hasViewedProperty-${v}`}
                  dataCY={PROP_DETAILS.SO_HAS_VIEWED}
                  value={v}
                  onChange={() => {
                    onChange({
                      field: 'hasViewedProperty',
                      value: v,
                    });
                  }}
                  name="hasViewedProperty"
                  checked={v === hasViewedProperty.value}
                />
              </Cell>
            ))}
          </Grid>
        </Fieldset>
      </Cell>
      {/* Comments textarea and a spam protection hidden field */}
      <Cell>
        <TextArea
          label="Comments"
          id={fieldID('comments')}
          name={fieldID('comments')}
          value={comments.value}
          required
          onChange={(e) => {
            onChange({
              field: 'comments',
              value: e.target.value,
            });
          }}
          error={getError('comments')}
        />
      </Cell>
      <Cell>
        <RecaptchaV2
          onChange={(v) => {
            onChange({ field: 'token', value: v });
          }}
          error={getError('token')}
        />
      </Cell>
      <Cell>
        <ContactFormDisclaimer />
      </Cell>
    </Grid>
  );
};

/** @param {PropTypes.Requireable<any>} TValue The PropType of the value for the form field */
const fieldPropType = (TValue = PropTypes.string) =>
  PropTypes.shape({
    value: TValue.isRequired,
    touched: PropTypes.bool.isRequired,
  }).isRequired;

StartAnOfferFormFields.propTypes = {
  fullName: fieldPropType(),
  email: fieldPropType(),
  phone: fieldPropType(),
  offerAmount: fieldPropType(PropTypes.number),
  helpWithFinancing: fieldPropType(),
  hasViewedProperty: fieldPropType(),
  comments: fieldPropType(),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StartAnOfferFormFields;
