import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ValidateUrlProtocol } from 'utility/urlValidator';
import { openHouseVirtualData } from 'constants/registrationForms';
import { app } from 'BoomTown';
import { PROP_DETAILS } from 'cypress_constants';
import { SecondaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import Pill from 'components/core/Pill';
import OpenHouseInfo from '../OpenHouseInfo';

/**
 * @typedef OpenHouseCardProps
 *
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} description
 * @property {string} url
 * @property {boolean} isLive
 */

/**
 * Individual Open House Cards. Can be Virtual or Basic.
 *
 * @param {OpenHouseCardProps} props
 * @returns {JSX.Element}
 */
export default function OpenHouseCard(props) {
  const { startTime, endTime, description, url, isLive, visitorRegistered } = props;

  let cleanUrl;
  let showLivePill;
  let showJoinNowButton;
  let showSqueezeSection;
  let title = 'Open House';
  let modifier = '--basic';

  if (url) {
    title = 'Virtual Open House';
    modifier = '--virtual';
    cleanUrl = ValidateUrlProtocol(url);
    showLivePill = isLive;
    showJoinNowButton = showLivePill && visitorRegistered;
    showSqueezeSection = !visitorRegistered;
  }

  /**
   * Ideally this would the side-effect of an action being dispatched, but we'll leave it as is
   * as we'll be implementing an overall better solution as a result of the upcoming Backbone
   * migration work.
   */
  const onClickRegister = () => {
    app.squeezeForm(openHouseVirtualData.urlParam, true, false);
  };

  // Classes for the DOM element wrapping the title and Live pill
  const headerClasses = cx(
    'bt-flex bt-flex__align--center',
    'openhouse-card__header',
    `openhouse-card__header${modifier}`
  );

  return (
    <div
      className={`openhouse-card openhouse-card${modifier}`}
      data-cy={PROP_DETAILS.VIRTUAL_OPEN_HOUSE_CARD}
    >
      <div className={headerClasses} data-cy={PROP_DETAILS.VIRTUAL_OPEN_HOUSE_HEADER}>
        {title}
        {showLivePill && (
          <span className="openhouse-card__live-pill">
            <Pill bgColor="red">LIVE</Pill>
          </span>
        )}
      </div>

      <OpenHouseInfo
        start={startTime}
        end={endTime}
        url={cleanUrl}
        showVirtualUrl={Boolean(visitorRegistered && !isLive && url)}
      />

      {showJoinNowButton && (
        <Grid>
          <Cell xs={6} md={3}>
            <a
              href={cleanUrl}
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              className="gtm-virtual-open-house-link btn btn--size-small btn--secondary width-1-1 mt-12"
              data-cy={PROP_DETAILS.VIRTUAL_OPEN_HOUSE_JOIN_BUTTON}
            >
              Join Now
            </a>
          </Cell>
        </Grid>
      )}

      {description && <p className="mt-12">{description}</p>}

      {showSqueezeSection && (
        <div className="openhouse-card__squeeze">
          <div>
            To view and register for this {title.toLowerCase()}, you must have an account. Register
            or sign in below.
          </div>
          <SecondaryButton
            className="px-16 mt-12"
            size="small"
            width="auto"
            onClick={onClickRegister}
            dataCY={PROP_DETAILS.VIRTUAL_OPEN_HOUSE_REGISTER}
          >
            Register or Sign In
          </SecondaryButton>
        </div>
      )}
    </div>
  );
}

OpenHouseCard.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  isLive: PropTypes.bool,
  visitorRegistered: PropTypes.bool,
};
