var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../assets/js/helpers/lengthGT.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Schools") : depth0),0,{"name":"lengthGT","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":60,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"bt-panel--small\">\n      <h3 class=\"bt-heading__h3 uk-text-center m-0\">School Information</h3>\n    </div>\n    <div>\n      <div class=\"bt-listing-table bt-listing__table-break m-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Schools") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ShowSchoolDistrictInfo") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ClientIsCanadian") : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":36,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"grid\">\n            <div class=\"cell text-semibold"
    + ((stack1 = __default(require("../../../assets/js/helpers/ifEq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"E",{"name":"ifEq","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":99}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../assets/js/helpers/ifEq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"M",{"name":"ifEq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":99},"end":{"line":10,"column":146}}})) != null ? stack1 : "")
    + "\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TypeName") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":74}}})) != null ? stack1 : "")
    + ":\n            </div>\n            <div class=\"cell uk-text-right\">\n              <a data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"_ID") : depth0), depth0))
    + "\" data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"Name") : depth0), depth0))
    + "\" data=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"Name") : depth0), depth0))
    + "|school="
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"_ID") : depth0), depth0))
    + "\" data-type=\"school\" title=\"View more properties in this district\" href=\"/results-gallery/?school="
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"_ID") : depth0), depth0))
    + "\" class=\"school_link js-listing-schools\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"Name") : depth0), depth0))
    + "</a>\n            </div>\n          </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " cell-xs-7 cell-lg-6 cell-xl-5";
},"6":function(container,depth0,helpers,partials,data) {
    return " cell-lg-5 cell-xl-4";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"TypeName") : depth0), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Type") : depth0), depth0))
    + " School";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":10},"end":{"line":26,"column":17}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"grid\">\n            <div class=\"cell text-semibold\">School District:</div>\n            <div class=\"cell uk-text-right\">\n              <a data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\" data-type=\"schooldistrict\" href=\"/results-gallery/?schooldistrict="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "&status=A\" title=\"View more properties in "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</a>\n            </div>\n          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideSchoolsInfoLink") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":35,"column":15}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"uk-text-right bt-panel--small\">\n        <a data-cy=\"prop-details__school-info-link\" rel=\"nofollow\" target=\"_blank\" href=\"//www.greatschools.org/search/nearbySearch.page?gradeLevels=&amp;distance=15&amp;zipCode="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"PostalCode") : stack1), depth0))
    + "&amp;redirectUrl=%2F\" title=\"School Ratings &#38; Info\" class=\"js-listing-schoolsBtn uk-text-small\">School Ratings &amp; Info</a>\n    </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ClientIsCanadian") : stack1),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":59,"column":15}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bt-panel--small\">\n          <h3 class=\"bt-listing__headline uk-text-center m-0\">School Ratings &amp; Info</h3>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ShowSchoolDistrictInfo") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSchoolsInfoLink") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":58,"column":19}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"bt-listing-table bt-listing__table-break m-0\">\n                  <div class=\"grid\">\n                    <div class=\"cell text-semibold\">School District:</div>\n                    <div class=\"cell uk-text-right\">\n                        <a data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\" data-type=\"schooldistrict\" href=\"/results-gallery/?schooldistrict="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "&status=A\" title=\"View more properties in "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</a>\n                    </div>\n                  </div>\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bt-panel--small\">\n          <a role=\"button\" data-cy=\"prop-details__school-info-link\" href=\"//www.greatschools.org/search/nearbySearch.page?gradeLevels=&amp;distance=15&amp;zipCode="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"PostalCode") : stack1), depth0))
    + "&amp;redirectUrl=%2F\" class=\"js-listing-schoolsBtn btn btn--primary btn--width-full\" target=\"_blank\" rel=\"nofollow\">Visit GreatSchools.org</a>\n        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ClientIsCanadian") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":2},"end":{"line":83,"column":13}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"bt-panel--small\">\n        <h3 class=\"bt-listing__headline uk-text-center m-0\">School Ratings &amp; Info</h3>\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ShowSchoolDistrictInfo") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":6},"end":{"line":77,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSchoolsInfoLink") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":6},"end":{"line":82,"column":17}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":76,"column":17}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"bt-listing-table bt-listing__table-break m-0\">\n                <div class=\"grid\">\n                  <div class=\"cell text-semibold\">School District:</div>\n                  <div class=\"cell uk-text-right\">\n                      <a data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\" data-type=\"schooldistrict\" href=\"/results-gallery/?schooldistrict="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + "&status=A\" title=\"View more properties in "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SchoolDistrict") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</a>\n                  </div>\n                </div>\n              </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"bt-panel--small\">\n        <a data-cy=\"prop-details__school-info-link\" role=\"button\" href=\"//www.greatschools.org/search/nearbySearch.page?gradeLevels=&amp;distance=15&amp;zipCode="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"PostalCode") : stack1), depth0))
    + "&amp;redirectUrl=%2F\" class=\"js-listing-schoolsBtn btn btn--primary btn--width-full-\" target=\"_blank\" rel=\"nofollow\">Visit GreatSchools.org</a>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"specialRules") : depth0)) != null ? lookupProperty(stack1,"ShowSchoolInfo") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":84,"column":7}}})) != null ? stack1 : "");
},"useData":true});