import Url from 'url-parse';

// eslint-disable-next-line import/prefer-default-export
export function ValidateUrlProtocol(url) {
  const parser = new Url(url, {});
  if (!parser.protocol) {
    parser.set('protocol', 'https');
  }
  return parser.href;
}
