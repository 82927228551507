/* global google */

import { addScrollspyOnce, removeScrollspyListener } from 'utility/scrollspy';
import { create as createMap, destroy as releaseMap } from 'utility/MapPool/mapPool';

/**
 * The Google Map shown on the listing details page. Encapsulates specific
 * settings and implements lazy loading on scroll.
 */
export default class ListingDetailsMap {
  /**
   * Y-coordinate offset for the scrollspy in pixels. Comprised of height of element (400px) plus
   * a 100px the bottom of the viewport.
   */
  scrollSpyYOffset = 500;

  constructor({ el, lat, lng, address, isDraggable, enableScrollSpy }) {
    /** @type {HTMLElement} */
    this.el = el;
    this.lat = lat;
    this.lng = lng;
    this.address = address;
    this.isDraggable = isDraggable;
    this.enableScrollSpy = enableScrollSpy;

    /**
     * Null initially, but will be assigned to when the map is created.
     *
     * @type {google.maps.Map?}
     */
    this.map = null;

    /**
     * @type {google.maps.Marker?}
     */
    this.marker = null;

    if (this.enableScrollSpy) {
      addScrollspyOnce(el, this._createMap, { x: 0, y: this.scrollSpyYOffset });
    } else {
      this._createMap();
    }
  }

  /**
   * Clean up any event listeners this view creates.
   *
   * @public
   */
  viewWillUnmount() {
    if (this.enableScrollSpy) {
      removeScrollspyListener(this.el, this._createMap);
    }
    if (this.map !== null) {
      releaseMap(this.map);
    }
    if (this.marker !== null) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }

  /**
   * Create a Google Map instance.
   *
   * @private
   */
  _createMap = () => {
    const { lat, lng, isDraggable, address } = this;
    const latlng = new google.maps.LatLng(lat, lng);

    // Default Map View
    const opts = {
      zoom: 16,
      minZoom: 5,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      draggable: isDraggable,
      styles: window.bt.mapstyle.default,
      clickableIcons: false,
      mapTypeControl: false,
    };
    const map = createMap(opts, 'uk-height-1-1', this.el);
    this.map = map;

    this.marker = new google.maps.Marker({
      clickable: false,
      position: latlng,
      map: /** @type {google.maps.Map} */ (this.map),
      title: address,
    });
  };
}
