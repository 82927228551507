import PropTypes from 'prop-types';
import React from 'react';
import { connectBBHoc } from 'hoc/connectBB';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { config, favorites } from 'BoomTown';
import * as StartAnOfferActions from 'actions/StartAnOfferActions';
import { PrimaryButton, SecondaryButton } from 'coreComponents';
import { PROP_DETAILS } from 'cypress_constants';
import { CCompDropdown } from 'components/Common/Dropdown';
import { Grid, Cell } from 'components/core/Grid';
import ShareOptions from 'components/DetailsPage/PropHeader/ShareOptions';
import FAIcon from 'components/core/FAIcon';
import cx from 'classnames';
import StartAnOfferModal from 'components/Modals/StartAnOffer';

const ListingDetailsCallToActionButtons = (props) => {
  const {
    address,
    hideMap,
    isSold,
    photo,
    social,
    url,
    isFavorite,
    hideStartAnOfferForm,
    showStartAnOfferForm,
    startOffer,
    startAnOfferIsOpen,
    listingID,
    listPrice
  } = props;

  return (
    <div className="bt-listing__header-cta__wrapper pr-16 cell-lg-12 cell-md-12">
      <Grid guttersSmall>
        <Cell className="bt-listing__header-cta__container">
          {isSold ? (
            <PrimaryButton
              className="at-contact-agent-btn bt-listing__header-cta__btn js-listing-contact-agent"
              width="full"
            >
              Contact Agent
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className="at-request-show-btn bt-listing__header-cta__btn js-listing-request-showing"
              width="full"
              dataCY={PROP_DETAILS.SCHEDULE_SHOWING_BUTTON}
            >
              {config.disableVideoTour ? 'Go See It' : 'Tour in Person'}
            </PrimaryButton>

          )}
        </Cell>
        {/* CNS-6752 - Disable Video Tour */}
        {config.disableVideoTour && startOffer && (
          <Cell className="bt-listing__header-cta__container">
            <PrimaryButton
              className="at-start-an-offer-btn bt-listing__header-cta__btn js-start-offer-showing"
              onClick={() => {
                showStartAnOfferForm({ listingId: listingID, listPrice, address });
              }}
              width="full"
              dataCY={PROP_DETAILS.START_OFFER_BUTTON}
            >
              Start an Offer
            </PrimaryButton>
            <StartAnOfferModal
              onDismiss={() => {
                hideStartAnOfferForm();
              }}
              show={startAnOfferIsOpen}
            >
              Start an Offer
            </StartAnOfferModal>
          </Cell>
        )}
        {!config.disableVideoTour && (
          <Cell className="bt-listing__header-cta__container uk-position-relative">
            <PrimaryButton
              className="at-request-video-tour bt-listing__header-cta__btn js-listing-request-video-tour uk-position-relative"
              width="full"
              dataCY={PROP_DETAILS.VIDEO_TOUR_BUTTON}
            >
              Video Tour
            </PrimaryButton>
          </Cell>
        )}
        {/* **************************************************************************************
          * CNS-6735: startOffer modal temporarily removed in favor of tour via video chat button*
          ****************************************************************************************
          */}
      </Grid>
      <Grid guttersSmall>
        <Cell>
          <CCompDropdown
            className="width-1-1"
            align="center"
            trigger={
              <SecondaryButton
                width="full"
                size="small"
                className="js-listing-share at-share-btn"
                dataCY={PROP_DETAILS.SHARE_BUTTON}
              >
                <span className="bt-icon--stack">
                  <FAIcon
                    icon="share-square"
                    type="solid"
                    className="mx-auto my-4 uk-display-block font-size--16"
                  />
                </span>
                Share
              </SecondaryButton>
            }
            content={<ShareOptions address={address} photo={photo} social={social} url={url} />}
          />
        </Cell>
        {!hideMap && (
          <Cell>
            <SecondaryButton className="js-listing-map" size="small" width="full">
              <span className="bt-icon--stack">
                <FAIcon
                  icon="map-marker-alt"
                  type="solid"
                  className="mx-auto my-4 uk-display-block font-size--16"
                />
              </span>
              Map
            </SecondaryButton>
          </Cell>
        )}
        <Cell>
          <SecondaryButton
            width="full"
            size="small"
            className={cx(
              'btn-favorite btn-toggle js-details-fav',
              { 'btn-favorite--active': isFavorite })
            }
            isActive={isFavorite}
            dataCY={PROP_DETAILS.FAVORITE_BUTTON}
          >
            <span className="bt-icon--stack">
              <FAIcon
                icon="heart"
                type="solid"
                className="mx-auto my-4 uk-display-block font-size--16"
              />
            </span>
            {isFavorite ? 'Favorited' : 'Favorite'}
          </SecondaryButton>
        </Cell>
      </Grid>
    </div>
  );
};

ListingDetailsCallToActionButtons.propTypes = {
  address: PropTypes.string,
  hideMap: PropTypes.bool,
  hideStartAnOfferForm: PropTypes.func,
  isSold: PropTypes.bool,
  photo: PropTypes.string,
  showStartAnOfferForm: PropTypes.func,
  social: PropTypes.bool,
  startOffer: PropTypes.bool,
  startAnOfferIsOpen: PropTypes.bool,
  url: PropTypes.string,
  isFavorite: PropTypes.bool,
  listingID: PropTypes.number,
  listPrice: PropTypes.number,
  dataCY: PropTypes.string,
};

ListingDetailsCallToActionButtons.defaultProps = {
  address: '',
  hideMap: false,
  isSold: false,
  photo: '',
  social: true,
  startOffer: false,
  startAnOfferIsOpen: false,
  url: '',
  isFavorite: false,
  listingID: null,
  listPrice: '',
};

export default compose(
  connectBBHoc([
    {
      getEmitter: () => favorites,
      events: [
        {
          name: 'add remove',
          replaceProps: props => ({
            isFavorite: Boolean(favorites.get(props.listingID)),
          }),
        },
      ],
    },
  ]),
  connect(
    state => ({
      startAnOfferIsOpen: state.startAnOffer.isOpen,
    }),
    StartAnOfferActions
  )
)(ListingDetailsCallToActionButtons);
