/* global google */

/** @type {MapPool.PoolItem[]} */
const _pool = [];

/**
 * Get or create a new instance of a Google Map.
 *
 * @param {Object} options
 * @param {string} classes
 * @param {Element} el
 * @returns {google.maps.Map} map
 */
export function create(options, classes, el) {
  const unused = _pool.find(x => !x.active);
  let map;

  if (unused) {
    map = reuseMap(unused, el, options, classes);
  } else {
    map = newMap(el, options, classes);
  }

  return map;
}

/**
 * Mark a map in the pool eligible for reuse.
 *
 * @param {google.maps.Map} map
 */
export function destroy(map) {
  const poolItem = _pool.find(x => x.map === map);
  if (!poolItem) {
    return;
  }
  poolItem.div.className = '';
  poolItem.active = false;
}

/**
 * Boolean fn to check if our pool has any inactive Map instances
 *
 * @returns {boolean}
 */
export function hasUnused() {
  return _pool.some(x => !x.active);
}

/**
 * Create a new map div, append it to an element from our Component, create a new google Map
 * instance, then add our new PoolItem to our MapPool array
 * @param {Element} el
 * @param {Object} options
 * @param {string} classes
 * @returns {google.maps.Map} map
 */
export function newMap(el, options, classes) {
  const div = document.createElement('div');
  div.className = classes;

  appendMapDivToEl(el, div);

  const map = new google.maps.Map(div, options);
  _pool.push({
    map,
    div,
    active: true,
  });
  return map;
}

/**
 * Update the unused PoolItem to active, set appropriate classes to the mapDiv, append the mapDiv
 * to an element from our Component, set new options on the google Map instance
 * @param {Object} unused
 * @param {Element} el
 * @param {Object} options
 * @param {string} classes
 * @returns {google.maps.Map} map
 */
/* eslint-disable no-param-reassign */
export function reuseMap(unused, el, options, classes) {
  unused.active = true;
  unused.div.className = classes;

  appendMapDivToEl(el, unused.div);

  // Since we are reusing map instances, it's important to trigger a resize
  // event, _then_ call setOptions(), after the element is in the document.
  // Note: This may not be necessary anymore
  google.maps.event.trigger(unused.map, 'resize');

  unused.map.setOptions(options);
  return unused.map;
}
/* eslint-enable no-param-reassign */

/**
 * Attempts to append a mapDiv to an Element from our Component, throws an error if no
 * Element exists
 * @param {Element} el
 * @param {Element} mapDiv
 */
function appendMapDivToEl(el, mapDiv) {
  try {
    el.appendChild(mapDiv);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error: Unable to append Google Map div to the provided element');
  }
}
