import PropTypes from 'prop-types';
import React from 'react';
import AgentAvatar from 'components/Common/Agent/AgentAvatar';
import { PrimaryButton } from 'coreComponents';
import cx from 'classnames';
import BrandID from 'components/DetailsPage/ContactAgent/BrandID';
import Phone from './Phone';

export default function AgentLender(props) {
  const {
    rep,
    innerLogoUri,
    specialRules,
    tenant,
    isLender,
    showMobilePhone,
    showButton,
    showBranding,
    layout
  } = props;
  const type = cx(
    'font-size--14 cell',
    {
      'bt-listing__agent__info__category': true,
      'bt-listing__agent__info__category--agent': !props.isLender,
      'bt-listing__agent__info__category--lender': props.isLender
    }
  );

  const infoStyles = cx({
    'bt__agent-lender__card cell': layout === 'full',
    'bt-panel uk-panel uk-panel-box': layout === 'card'
  });

  const buttonType = cx(
    'js-listing-contact-agent',
    {
      'at-contact-agent-btn': !isLender,
      'at-contact-lender-btn': isLender
    }
  );

  // CNS-3646: Generate the correct title to display for agents and lenders
  // Use the agent/lender's Title attribute in the blue/green bars on the cards.
  // If the Title attr is undefined, then default to Associate or Lending Associate
  // for Agents or Lenders, respectively.
  const associateTitle = (() => {
    let title = 'Associate'; // Set our default title
    if (isLender) {
      title = 'Lending Associate';
    }
    return rep.Title || title;
  })();

  return (
    <div className={infoStyles}>
      <div itemType="http://schema.org/Person" itemScope>
        { !specialRules.HideAgentFromRequest && rep && (
          <div className="grid grid--gutters grid--flexCells">
            <div key="agent-avatar" className="bt-listing__agent_image cell cell-xs-4">
              <AgentAvatar agent={rep} isLender={isLender} tenantAlias={tenant.alias} />
            </div>
            <div key="agent-info" className="cell cell-xs-8">
              <div className="grid grid-xs--full width-1-1">
                <div className="cell">
                  <div className={type}>{associateTitle}</div>
                  <div className="bt-listing__agent__info">
                    <div className="bt-listing__agent__info__name bt-heading__h4" itemProp="name">
                      {
                        rep.FullName ?
                          <span>{rep.FullName}</span>
                          : <span>{rep.FirstName} {rep.LastName}</span>
                      }
                    </div>
                    <div className="bt-listing__agent__info__dre uk-text-muted mb-4">
                      {
                        rep.DRENumber ?
                          <span>CA DRE #{rep.DRENumber}</span>
                          : ''
                      }
                    </div>
                    <div className="uk-text-truncate-child bt-listing__agent__info__phone">
                      {
                        Boolean(rep.OfficePhone) && <Phone
                          number={rep.OfficePhone}
                          type="Office"
                        />
                      }
                      {
                        (showMobilePhone && Boolean(rep.MobilePhone)) &&
                          <Phone
                            number={rep.MobilePhone}
                            type="Cell"
                          />
                      }
                    </div>
                  </div>
                </div>
                {
                  showButton && !specialRules.HideAgentFromRequest && (
                    <div className="cell cell--bottom mt-auto">
                      <PrimaryButton
                        className={buttonType}
                        width="full"
                        onClick={props.contactClick}
                      >
                        { specialRules.HideAgentFromRequest || specialRules.HideContactMention ?
                          <span>Ask a Question</span> : <span>Contact {rep.FirstName || 'an Agent'}</span> }
                      </PrimaryButton>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )}
        { props.children }
        {
          showButton && specialRules.HideAgentFromRequest && !isLender && (
            <PrimaryButton
              className="at-contact-btn js-listing-contact-agent"
              onClick={props.contactClick}
              width="full"
            >
              { specialRules.HideAgentFromRequest || specialRules.HideContactMention ?
                <span>Ask a Question</span>
                : <span>Contact {rep.FirstName || 'an Agent'}</span> }
            </PrimaryButton>
          )
        }
        {
          showBranding && (
            <BrandID
              name={!isLender ? tenant.legalName : rep.CompanyName}
              rep={rep}
              innerLogoUri={!isLender ? innerLogoUri : rep.LogoUrl}
              tenant={tenant}
              isLender={isLender}
              hideAgent={specialRules.HideAgentFromRequest}
            />
          )
        }
      </div>
    </div>
  );
}

AgentLender.displayName = 'AgentLender';
AgentLender.propTypes = {
  rep: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    FullName: PropTypes.string,
    Title: PropTypes.string,
    Slug: PropTypes.string,
    OfficePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    MobilePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    CompanyName: PropTypes.string,
    LogoUrl: PropTypes.string,
    Address1: PropTypes.string,
    Street: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  innerLogoUri: PropTypes.string,
  tenant: PropTypes.shape({
    alias: PropTypes.string,
    legalName: PropTypes.string,
    isAgentSubdomain: PropTypes.bool.isRequired,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  specialRules: PropTypes.shape({
    HideAgentFromRequest: PropTypes.bool,
    HideContactMention: PropTypes.bool
  }),
  showMobilePhone: PropTypes.bool,
  showButton: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  contactClick: PropTypes.func,
  isLender: PropTypes.bool,
  showBranding: PropTypes.bool,
  layout: PropTypes.oneOf([
    'Full',
    'full',
    'Card',
    'card'
  ])
};

AgentLender.defaultProps = {
  layout: 'card',
  showMobilePhone: false,
  showButton: false,
  showBranding: false,
  contactClick: function noop() {},
};
