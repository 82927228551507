import PropTypes from 'prop-types';
import React from 'react';
import { Input, Select } from 'coreComponents';
import IsMobile from 'components/IsMobile';
import { MobilePriceInput } from 'components/Common/FormElements';
import { Grid, Cell } from 'components/core/Grid';
import FAIcon from 'components/core/FAIcon';
import MobileMortgageCalcResults from 'components/MobileDetailsPage/MobileMortgageCalc/results';
import { MORTGAGE_CALCULATOR } from 'cypress_constants';

const MobileMortgageCalcForm = (props) => {
  const {
    listPrice,
    downPayment,
    percentDown,
    loanType,
    loanTypeOptions,
    interestRate,
    interestRateHelper,
    displaytaxAndIns,
    taxAndInsAmount,
    taxAndInsPercent,
    monthlyTax,
    principalAndInterest,
    estimatedMonthlyPayment,
    principal,
    handleOnBlur,
    handleOnChange,
  } = props;

  return (
    <form>
      <Grid gutters xs="full" md="halves">
        <Cell>
          <Grid xs="full">
            <Cell>
              <MobilePriceInput
                label="Home Price"
                className="at-price-txt"
                name="price"
                id="MobileMortgageCalcForm-listPrice"
                dataCY={MORTGAGE_CALCULATOR.LIST_PRICE}
                onPriceChange={handleOnBlur.listPrice}
                value={listPrice}
              />
            </Cell>
            <Cell>
              <Grid guttersSmall>
                <Cell xs={8}>
                  <MobilePriceInput
                    label="Down Payment"
                    className="at-downPayment-txt"
                    name="downPayment"
                    id="MobileMortgageCalcForm-downPayment"
                    dataCY={MORTGAGE_CALCULATOR.DOWN_PAYMENT}
                    value={downPayment}
                    onPriceChange={handleOnBlur.downPayment}
                  />
                </Cell>
                <Cell xs={4}>
                  <Input
                    label="%"
                    aria-label="Down Payment Percentage"
                    className="width-1-2"
                    name="percentDown"
                    id="MobileMortgageCalcForm-percentDown"
                    dataCY={MORTGAGE_CALCULATOR.PERCENT_DOWN}
                    value={percentDown}
                    maxLength={5}
                    onBlur={(e) => handleOnBlur.percentDown(e)}
                    onChange={(e) => handleOnChange(e)}
                    grouped
                    suffix={<FAIcon icon="percentage" />}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell>
              <Select
                label="Loan Type"
                className="at-loanType-sel"
                name="loanType"
                id="MobileMortgageCalcForm-loanType"
                dataCY={MORTGAGE_CALCULATOR.LOAN_TYPE}
                value={loanType}
                onBlur={(e) => handleOnBlur.loanType(e)}
                onChange={(e) => handleOnChange(e)}
                options={loanTypeOptions}
              />
            </Cell>
            <Cell>
              <Input
                label="Interest Rate - %"
                className="width-1-2"
                name="interestRate"
                id="MobileMortgageCalcForm-interestRate"
                dataCY={MORTGAGE_CALCULATOR.INTEREST_RATE}
                value={interestRate}
                maxLength={5}
                onBlur={(e) => handleOnBlur.interestRate(e)}
                onChange={(e) => handleOnChange(e)}
                helper={interestRateHelper}
                grouped
                suffix={<FAIcon icon="percentage" />}
              >
                <a href="/finance" className="font-size--14">
                  Contact Our Lending Specialist
                </a>
              </Input>
            </Cell>
            {displaytaxAndIns && (
              <Cell>
                <Grid guttersSmall alignItems="bottom">
                  <Cell xs={8}>
                    <MobilePriceInput
                      label="Est. Tax &amp; Insurance/mo."
                      name="taxAndInsAmount"
                      id="MobileMortgageCalcForm-taxAndInsAmount"
                      dataCY={MORTGAGE_CALCULATOR.TAX_AND_INS_AMOUNT}
                      value={taxAndInsAmount}
                      onPriceChange={handleOnBlur.taxAndInsAmount}
                    />
                  </Cell>
                  <Cell xs={4}>
                    <Input
                      label="%"
                      className="width-1-2"
                      name="taxAndInsPercent"
                      id="MobileMortgageCalcForm-taxAndInsPercent"
                      dataCY={MORTGAGE_CALCULATOR.TAX_AND_INS_PERCENT}
                      value={taxAndInsPercent}
                      maxLength={5}
                      onBlur={(e) => handleOnBlur.taxAndInsPercent(e)}
                      onChange={(e) => handleOnChange(e)}
                      grouped
                      suffix={<FAIcon icon="percentage" />}
                    />
                  </Cell>
                </Grid>
              </Cell>
            )}
          </Grid>
        </Cell>
        <Cell>
          <IsMobile>
            {(isMobile) =>
              isMobile ? (
                <MobileMortgageCalcResults
                  principalAndInterest={principalAndInterest}
                  principal={principal}
                  monthlyTax={monthlyTax}
                  estimatedMonthlyPayment={estimatedMonthlyPayment}
                />
              ) : (
                <div className="uk-panel uk-panel-box height-1-1">
                  <h4>Estimated Monthly Payment</h4>
                  <MobileMortgageCalcResults
                    principalAndInterest={principalAndInterest}
                    principal={principal}
                    monthlyTax={monthlyTax}
                    estimatedMonthlyPayment={estimatedMonthlyPayment}
                  />
                </div>
              )
            }
          </IsMobile>
        </Cell>
      </Grid>
      <div className="text-xs--center font-size--12 bt-disclaimer--mortgage-calculator">
        Mortgage Calculator Provided by BoomTown
      </div>
    </form>
  );
};

MobileMortgageCalcForm.propTypes = {
  listPrice: PropTypes.number.isRequired,
  downPayment: PropTypes.number.isRequired,
  percentDown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loanType: PropTypes.string,
  loanTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  interestRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  interestRateHelper: PropTypes.string,
  displaytaxAndIns: PropTypes.bool,
  taxAndInsAmount: PropTypes.number,
  taxAndInsPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  monthlyTax: PropTypes.string,
  principalAndInterest: PropTypes.string,
  estimatedMonthlyPayment: PropTypes.string,
  principal: PropTypes.string,
  handleOnBlur: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default MobileMortgageCalcForm;
