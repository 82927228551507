import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Implements the UIkit [alert component] [1]. Currently does not support a close
 * button, but this could be added in the future.
 *
 * [1]: http://getuikit.com/docs/alert.html
 */
const Alert = ({ type, className, children, dataCY }) => {
  const classes = cx(
    'alert',
    className,
    {
      [`alert-${type}`]: type,
    }
  );

  return (
    <div className={classes} data-cy={dataCY && `${dataCY}__${type}`}>
      {children}
    </div>
  );
};

Alert.displayName = 'Alert';
Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
  className: PropTypes.string,
  children: PropTypes.node,
  dataCY: PropTypes.string,
};

export default Alert;
