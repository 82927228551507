/* eslint-disable no-param-reassign, default-case, new-cap, prefer-template, no-unused-vars */
import $ from 'jquery';
import React, { createElement } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import UIkit from 'uikit';
import _ from 'underscore';
import * as MapPool from 'utility/MapPool/mapPool';
import bt from 'BoomTown';
import store from 'store';

import { View } from 'backbone';
import Listing from 'legacy/Model/listing';
import Agent from 'legacy/Model/agent';
import ListingCardView from 'legacy/Views/Listings/card';
import EmailListingView from 'legacy/Views/Modals/emaillisting';
import ContactAgent from 'components/DetailsPage/ContactAgent';
import ListingDetailsCallToActionButtons from 'components/DetailsPage/CallToActionButtons';
import AgentLenderWrapper from 'components/DetailsPage/AgentLenderWrapper';
import Flickity from 'flickity-bg-lazyload';
import StartAnOfferButton from 'components/StartAnOfferButton';
import MortgageCalculator from 'components/MobileDetailsPage/MobileMortgageCalc';
import OpenHouses from 'components/Common/OpenHouses';
import Pill from 'components/core/Pill';
import { showStartAnOffer } from 'selectors/listings';
import { shareListingData, photoSliderData, registerData } from 'constants/registrationForms';
import setVirtualOpenHousesLive from 'components/Common/OpenHouses/setVirtualOpenHousesLive';

import template from 'templates/listings/details/base.hbs';
import offMarketTemplate from 'templates/listings/off-market/base.hbs';

import * as a from './actions';

import ListingDetailsMap from '../ListingDetailsMap';

export default class SingleListingView extends View {
  get id() {
    return 'listings_view_single';
  }

  get attributes() {
    return { class: ['listings_view_single', 'at-single-view'] };
  }

  // CNS-6735
  get events() {
    return {
      'click .js-streetview': 'onStreetView',
      'click .js-birdview': 'onBirdView',
      'click .js-btnGetDirections': 'getDirections',
      'click .js-mortgage-modal': 'loadMortgageCalculator',
      'click .js-email-listing': 'loadEmailListing',
      'focus .js-listing__directions-from': 'autocompleteAddress',
      'click .js-listing-request-showing': 'loadRequestForm',
      'click .js-listing-request-video-tour': 'loadVideoRequestForm',
      'click .js-listing-contact-agent': 'loadContactAgentForm',
      'click .js-listing-share': 'loadShareDropdown',
      'click .js-signin': 'signin',
      'click .js-signup': 'signup',
      'click .js-details-fav': 'handleFavoriteClick',
      'click .js-back-to-search': 'goBack',
      'click .js-call-agent': 'logcall',
      'click .js-listing-print': 'logPrint',
      'click .js-listing-map': 'mapAnchor',
      'click .js-listing-neighborhood, .js-listing-county, .js-listing-area, .js-listing-schools':
        'handleSearchTag',
      'click .js-listing-schoolsBtn': 'greatSchoolsSearch',
      'click .js-showingDate': 'scrollToDatePicker',
      'click .js-listing-photoslider-cta': 'loadRequestForm',
      'click .js-listing-photoslider-video-cta': 'loadVideoRequestForm',
      'click .js-activate-dynamic-map': 'activateDynamicMap',
    };
  }

  template = template;
  offMarket = offMarketTemplate;
  listing = null;
  mortgage = null;
  hasLiveVirtualOpenHouse = false;

  /**
   * @type {ListingDetailsMap?}
   */
  mapView = null;

  bmap = false;
  smap = false;
  autocompleteDirections = null;
  autocompleteDirectionsInit = true;

  contactForm = {
    component: null,
    node: null,
    selector: '.jsx-listing-details-contact-form',
  };
  repCards = {
    component: null,
    node: null,
    selector: '.jsx-listing-details-rep-tags',
  };
  callToActions = {
    component: null,
    node: null,
    selector: '.jsx-listing-details-header-cta',
  };
  startAnOfferButton = {
    component: null,
    node: null,
    selector: '.jsx-start-an-offer-button',
  };
  mortgageCalc = {
    component: null,
    node: null,
    selector: '.jsx-mortgage-calc-form',
  };
  openHouses = {
    component: null,
    node: null,
    selector: '.jsx-openhouses',
  };
  virtualOpenHouseLive = {
    component: null,
    node: null,
    selector: '.jsx-sash__live-pill',
  };

  // flickity sliders
  listingGallery = null;
  listingThumbnails = null;
  relatedSlider = null;

  initialize() {
    super.initialize();
    this.attributes.class.forEach(c => this.el.classList.add(c));

    this.googleMapsPromise = bt.deps.loadGoogleMaps();

    this.listenTo(bt.favorites, 'add', function onFavoriteAdd(favModel) {
      const listingID = this.listing ? this.listing.id : 0;
      if (favModel.id === listingID) {
        this.$('.js-details-fav__container').addClass('favorited');
      }
    });
    this.listenTo(bt.favorites, 'remove', function onFavoriteRemove(favModel) {
      if (favModel.id === (this.listing != null ? this.listing.id : undefined)) {
        this.$('.js-details-fav__container').removeClass('favorited');
      }
    });

    // Conglomeration of tenant state to be passed as tenant prop in both React
    // components mounted on this view. (_Note that `account` and `tenant` are
    // each subsets of the same data from the API (/lc/1/account), passed into
    // the app in two different places. Check `main.php` and `wp-base-
    // theme/**/assets.php`._)
    this.tenant = {
      alias: bt.account.get('Alias'),
      isAgentSubdomain: this.isAgentSubdomain(),
      isCanadian: bt.rules.get('ClientIsCanadian'),
      subDomainAgentID: bt.agent != null ? bt.agent.id : undefined, // TODO: Also incorrect
      legalName: bt.tenant.legalName,
      street: bt.tenant.street,
      city: bt.tenant.city,
      state: bt.tenant.state,
      zip: bt.tenant.zip,
    };

    // A fn used to cancel the XHR for fetching the listing. Either a no-op or
    // the abort() method on the XHR
    this.cancelGetListing = function noop() {};
  }

  mount({ router }) {
    const listingID = parseInt(router.params.listingID, 10);
    this.render(listingID);
  }

  viewWillUnmount() {
    this.cancelGetListing();
    this.$el.empty();
    this.stopListening();
    this.attributes.class.forEach(c => this.el.classList.remove(c));

    this.maybeUnmountFlickity();

    if (this.mapView) {
      this.mapView.viewWillUnmount();
      this.mapView = null;
    }

    this.unmountComponent(this.contactForm);
    this.unmountComponent(this.repCards);
    this.unmountComponent(this.mortgageCalc);
    this.unmountComponent(this.callToActions);
    this.unmountComponent(this.startAnOfferButton);
    this.unmountComponent(this.openHouses);
    if (this.hasLiveVirtualOpenHouse) {
      this.unmountComponent(this.virtualOpenHouseLive);
    }

    $(document).off('keydown'); // TODO: THIS SEEMS A LITTLE GLOBAL

    // Modal subviews
    if (this.emailListing != null) {
      this.emailListing.remove();
    }
    if (this.mortgage != null) {
      this.mortgage.remove();
    }
  }

  update({ router }) {
    const listingID = parseInt(router.params.listingID, 10);
    if (this.listing.id !== listingID) {
      this.render(listingID);

      // Release the map
      if (this.mapView) {
        this.mapView.viewWillUnmount();
        this.mapView = null;
      }
    }
  }

  /**
   * Fetch listing data and render the view.
   * @param {number} listingID
   */
  render(listingID) {
    // make sure we are at the top of the page
    $(window).scrollTop(0);

    // Soft render the page if we have any data on this listing
    const l = bt.listings.get(listingID);
    if (l && l.has('shouldDisplayAsOffMarket')) {
      const snapshot = {
        ...l.attributes,
        googleMapsKey: bt.account.get('GMapKey'),
        specialRules: bt.rules.attributes,
        ShowMap: bt.visitor.isBot() ? false : l.attributes.ShowMap,
        skipPrintView: true,
      };

      if (l.get('shouldDisplayAsOffMarket')) {
        this.$el.html(this.offMarket(snapshot));
      } else {
        this.$el.html(this.template(snapshot));
      }
    }

    // Local fn to wrap fetching a listing in a Promise
    const getListing = id => {
      // If this is the same ID that is in the store we don't make
      // an api request
      if (bt.listing && bt.listing.get('_ID') === id) {
        return Promise.resolve(bt.listing.toJSON());
      }

      return new Promise((res, rej) => {
        const xhr = bt.api.getListing(null, id, resObj => {
          // Revert this method back to a no-op
          this.cancelGetListing = function noop() {};

          if (resObj.Status.Code === 200) {
            res(resObj.Result);
          } else {
            rej(resObj);
          }
        });

        this.cancelGetListing = xhr.abort;
      });
    };

    const listingPromise = getListing(listingID);

    // Waiting for Google maps here because of initUI
    const renderPromise = Promise.all([listingPromise, this.googleMapsPromise]).then(
      ([listingData]) => {
        listingData.Tenant = bt.account.attributes;
        listingData.specialRules = Object.assign({}, bt.rules.attributes);

        // We don't want to refire modals, pageviews if you open/closed the search menu
        // bt.listing is undefined on the first listing view
        let logView = true;
        if (bt.listing && bt.listing.get('_ID') === listingData._ID) {
          logView = false;
        }

        this.listing = bt.listing = new Listing(listingData);
        this.listing.beefUp();

        // Determine if any Virtual Open Houses are live
        if (this.listing.get('SashType') === 'virtualopenhouse') {
          const openHouses = setVirtualOpenHousesLive(this.listing.get('OpenHouses'));
          this.listing.set({ OpenHouses: openHouses });

          this.hasLiveVirtualOpenHouse = openHouses.findIndex(oh => oh && oh.isLive === true) !== -1;
        }

        // Upgrade the path to the canonical now that we have more info - post beef
        const p = '/listing/';
        const isUglyURL = window.location.pathname.substr(0, p.length) === p;
        if (isUglyURL && this.listing.has('Url')) {
          history.replaceState(history.state, '', this.listing.get('Url'));
        }

        if (this.listing.attributes.Tenant.GuestUnbranded) {
          if (bt.visitor.isRegistered()) {
            this.listing.attributes.Tenant.GuestUnbranded = false;
          } else {
            this.listing.attributes.specialRules.HideAgentFromRequest = true;
          }
        }

        // TODO: deprecate this, it increments the FullViews
        // and will overwrite the data cookie with model/visitor.attributes
        // Might fire a modal from visitor.js
        if (logView) {
          bt.events.trigger('listingData', this.listing);
        }

        // send listing meta to dataLayer
        store.dispatch(a.createDataLayer(this.listing.attributes));

        if (this.useActiveTemplate()) {
          this.$el.empty().html(
            this.template({
              ...this.listing.attributes,
              googleMapsKey: bt.account.get('GMapKey'),
              // specialRules has already been added to the listing above
              ShowMap: bt.visitor.isBot() ? false : this.listing.attributes.ShowMap,
              skipPrintView: false,
              photoSliderCTA: this.listing.get('PhotoCount') > 0,
            })
          );
        } else {
          this.$el.empty().html(this.offMarket(this.listing.attributes));
        }
        // Update the title tag
        document.title = this.listing.get('Title');

        return this.initUI();
      }
    );

    // Make sure that the previous continuation has been called before
    // rendering the fetched agent.
    Promise.all([this.getAgentForDisplay(listingID), renderPromise]).then(([agentModel]) => {
      // Since we use the listing object as the data passed to render the
      // template, we add properties to it. This is confusing and we need to
      // just pass an object with a listing property instead.
      this.listing.attributes.AgentForDisplay = agentModel.attributes;
      this.mountCallToActionButtons();
      this.mountStartAnOfferButton();
      this.mountContactForm();
      this.mountRepCards();
    });
  }

  /**
   * This will be called on server side load of a details page,
   * but can also be called again if unmount and remount this component without adding
   * location_changes
   *
   * @param {{listingID: number}}
   */
  bindToDOM({ listingID }) {
    this.delegateEvents();

    const { listing: bootstrapListing } = window.bt_data;

    if (!bootstrapListing) {
      return;
    }

    const logView = Boolean(!bt.listing);

    // TODO: Split `bt_data.listing` into an object that has listing and agent properties.
    this.listing = bt.listing = new Listing(bootstrapListing);
    this.listing.beefUp();

    // Determine if any Virtual Open Houses are live
    if (this.listing.get('SashType') === 'virtualopenhouse') {
      const openHouses = setVirtualOpenHousesLive(this.listing.get('OpenHouses'));
      this.listing.set({ OpenHouses: openHouses });

      this.hasLiveVirtualOpenHouse = openHouses.findIndex(oh => oh && oh.isLive === true) !== -1;
    }

    if (logView) {
      bt.events.trigger('listingData', this.listing);
    }

    // send listing meta to dataLayer
    store.dispatch(a.createDataLayer(this.listing.attributes));

    const initUIPromise = this.googleMapsPromise.then(() => this.initUI());

    // If there is not already an agent available to render (e.g. on $context
    // and passed via listing object from server), fetch one for display from
    // the API and render the components with the updated agent.
    if (this.listing.get('AgentForDisplay') == null) {
      Promise.all([this.getAgentForDisplay(this.listing.id), initUIPromise]).then((...args) => {
        const [agentModel] = Array.from(args[0]);
        this.listing.attributes.AgentForDisplay = agentModel.attributes;
        this.mountCallToActionButtons();
        this.mountStartAnOfferButton();
        this.mountOpenHouses();
        this.mountContactForm();
        return this.mountRepCards();
      });
    }
  }

  goBack(e) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    e.preventDefault();

    store.dispatch(a.clickBackToSearch());
  }

  getDirections(e_) {
    const self = this;
    const from = $('.js-listing__directions-from').val();
    let end = $('.js-listing__directions-to').val();
    const directionsPanel = $('.js-listing__directions-results');
    directionsPanel.html('');

    if (from === '') {
      $('.js-listing__directions-from').focus();
      return;
    }
    const directionsService = new window.google.maps.DirectionsService();
    self.directionsDisplay = new window.google.maps.DirectionsRenderer();

    this.googleMapsPromise.then(() => {
      if (!this.mapView) {
        this.loadMaps();
      }
      self.directionsDisplay.setMap(this.mapView.map);
      self.directionsDisplay.setPanel(directionsPanel[0]);

      const coords = bt.listing.get('Location').Coordinates;

      if (coords && coords.Latitude && coords.Longitude) {
        end = `${coords.Latitude}, ${coords.Longitude}`;
      }

      const request = {
        origin: from,
        destination: end,
        travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
      };

      if (bt.utility.validateParsley('.js-directions-form')) {
        directionsService.route(request, (response, status) => {
          const addressField = $('.js-listing__directions-from').parsley();
          if (status === window.google.maps.DirectionsStatus.OK) {
            window.ParsleyUI.removeError(addressField, 'myCustomError');
            bt.utility.show(directionsPanel);
            self.directionsDisplay.setDirections(response);
          } else {
            window.ParsleyUI.addError(
              addressField,
              'myCustomError',
              'Please enter a valid address.'
            );
          }
        });
      }
    });
  }

  /**
   * @return {boolean}
   */
  isActiveListing() {
    const statusCode = this.listing.get('StatusCode');
    if (!bt.rules.get('HideComingSoon')) {
      return statusCode === 'A' || statusCode === 'AC' || statusCode === 'CS';
    }

    return statusCode === 'A' || statusCode === 'AC';
  }

  /**
   * @return {boolean}
   */
  isSoldListing() {
    return this.listing.get('StatusCode') === 'S';
  }

  /**
   * @return {boolean}
   */
  isPendingListing() {
    return this.listing.get('StatusCode') === 'P';
  }

  /**
   * @return {bool}
   */
  isComingSoon() {
    if (!bt.rules.get('HideComingSoon')) {
      return this.listing.get('StatusCode') === 'CS';
    }

    return false;
  }

  /**
   * Sold and Pending listings are considered off-market, unless the
   * allowSoldData feature flag is true. (This should be the negation of the
   * materialized-in-beefUp `shouldDisplayAsOffMarket` property on the listing.)
   *
   * @return {boolean}
   */
  useActiveTemplate() {
    return (
      this.isActiveListing() ||
      (bt.config.allowSoldData && (this.isSoldListing() || this.isPendingListing()))
    );
  }

  initUI() {
    if (this.useActiveTemplate()) {
      if (this.listing.get('ShowMap') && MapPool.hasUnused()) {
        this.loadMaps();
      }
      this.mountCallToActionButtons();
      this.mountStartAnOfferButton();
      this.mountOpenHouses();
      this.mountContactForm();

      // AgentLender Cards by Prop Description
      // Displayed beside the "Description" section of the details page
      // Shows picture, info, and contact button
      this.mountRepCards();
      this.mountMortgageCalculator();

      if (this.emailListing != null) {
        this.emailListing.remove();
      }
      this.emailListing = new EmailListingView({ model: this.listing });

      // display share listing modal after shareform registration
      if (
        window.location.search.indexOf(
          `${bt.config.conversionQueryStringName}=${shareListingData.urlParam}`
        ) > -1
      ) {
        this.loadEmailListing();
      }
    }

    // TODO: If you stumble across why we punt on this add a comment
    setTimeout(() => {
      this.photoGallery();
      UIkit.sticky(
        this.$('.js-listing__header'),
        UIkit.Utils.options(this.$('.js-listing__header').attr('data-uk-sticky'))
      );
    }, 100);

    // Scroll to the form if our url says so, we do this for sold listings here
    // because there is no gallery of photos
    if (this.isSoldListing() && window.location.hash === '#listing-contact-agent-form') {
      this.loadContactAgentForm();
      this.contactForm.component.focusComments();
    }

    // Similar Listings
    const price = this.listing.get('ListPrice');
    const deviation = price * 0.15;
    const maxprice = parseInt(price, 10) + parseInt(deviation, 10);
    const minprice = parseInt(price - deviation, 10);
    const similarListings = {
      proptype: $.trim(this.listing.get('PropertyType')._ID),
      maxprice,
      minprice,
      postalcode: this.listing.get('Location').PostalCode,
      status: (bt.rules.get('HideComingSoon')) ? 'A,AC' : 'A,AC,CS',
      pagecount: 12,
      pageindex: 0,
      LogSearch: false,
    };

    // TODO: A route change should be able to abort this request
    bt.api.ajaxsearch(this, similarListings, this.loadSimilarListings);

    // DEPRECATED: We should just be calling lazy load reload on our own
    return bt.events.trigger('viewRendered');
  }

  loadMaps() {
    if (bt.visitor.isBot()) {
      return;
    }

    const l = this.listing.attributes;
    if (!l.Location.Coordinates || !l.Location.Coordinates.Latitude) {
      return;
    }

    const el = $('.js-listing__advanced-map')[0];
    if (!el) {
      return;
    }

    const address = `${l.Location.StreetNumber} ${l.Location.StreetName}, ${
      l.Location.CityDetail.Name
    }, ${l.Location.State} ${l.Location.PostalCode}`;

    $('.js-activate-dynamic-map').remove();
    $('.js-listing__map-object').removeClass('uk-hidden');
    $('.js-map-switcher').removeClass('uk-hidden');
    this.mapView = new ListingDetailsMap({
      el,
      lat: l.Location.Coordinates.Latitude,
      lng: l.Location.Coordinates.Longitude,
      address,
      isDraggable: !UIkit.support.touch,
      enableScrollSpy: MapPool.hasUnused(),
    });

    this.bindMapSwitcher();
  }

  onStreetView() {
    const l = this.listing.attributes;
    const latlng = new window.google.maps.LatLng(
      l.Location.Coordinates.Latitude,
      l.Location.Coordinates.Longitude
    );

    // Street View
    // is street view available
    const streetViewService = new window.google.maps.StreetViewService();
    streetViewService.getPanoramaByLocation(latlng, 50, (results, status) => {
      const $streetViewMap = this.$('.js-listing__streetview-map');

      if (status !== window.google.maps.StreetViewStatus.OK) {
        $streetViewMap.addClass('bt-no-streetview');
        bt.utility.show(this.$('.js-streetview-na'));
      } else {
        // this calculates an angle of the view to the property. For now lets use a default value. Tomas
        // var angle = self.computeAngle(self.latlng, results.location.latLng);
        // @log results
        const opts = {
          position: latlng,
          addressControl: false,
          linksControl: false,
          panControl: true,
          draggable: !UIkit.support.touch,
          scrollwheel: false,
          pov: {
            heading: 0,
            pitch: 10,
            zoom: 0,
          },
          enableCloseButton: false,
          visible: true,
        };

        this.smap = new window.google.maps.StreetViewPanorama($streetViewMap.get(0), opts);
      }
    });
  }

  onBirdView() {
    const l = this.listing.attributes;
    const latlng = new window.google.maps.LatLng(
      l.Location.Coordinates.Latitude,
      l.Location.Coordinates.Longitude
    );
    const address = `${l.Location.StreetNumber} ${l.Location.StreetName}, ${
      l.Location.CityDetail.Name
    }, ${l.Location.State} ${l.Location.PostalCode}`;

    // Bird's Eye View
    const opts = {
      scrollwheel: false,
      zoom: 20,
      minZoom: 5,
      center: latlng,
      draggable: !UIkit.support.touch,
      mapTypeId: 'satellite',
      mapTypeControl: false,
      streetViewControl: false,
      tilt: 45,
    };
    this.bmap = new window.google.maps.Map(this.$('.js-listing__birdview-map').get(0), opts);
    this.bmap.setHeading(90);

    /* eslint-disable no-new */
    new window.google.maps.Marker({
      clickable: false,
      position: latlng,
      map: this.bmap,
      title: address,
    });
    /* eslint-enable */
  }

  // This is an ajax callback, so be careful that you are operating on the dom you think you are
  loadSimilarListings(results) {
    if (results.Status.Code !== 200 || results.Result.Items.length < 1) {
      bt.utility.hide($('.js-related-properties__wrapper'));
      return;
    }

    const related = this.$('.js-related-properties');

    // CNS-4003: If this element is not on the page at the time this callback is
    // executed then abort
    if (related.length === 0) {
      return;
    }

    related.empty(); // TODO: NON ISSUE?

    const listingModels = results.Result.Items.filter(l => l._ID !== this.listing.id).map(l => {
      const model = new Listing(l);
      model.beefUp();
      return model;
    });
    const $cards = listingModels.map(model => {
      const view = new ListingCardView({
        model,
        inCollection: false,
      });
      return $('<div class="bt-card-wrapper">').append(view.$el);
    });

    if ($cards.length) {
      related.append(...Array.from($cards || []));
      bt.events.trigger('RelatedPropsImpression', listingModels);
      this.relatedSlider = new Flickity(related.get(0), {
        cellSelector: '.bt-card-wrapper',
        percentPosition: false,
        lazyLoad: 1,
        pageDots: false,
        wrapAround: true,
        cellAlign: 'left',
      });
      this.maybeHideArrows(related, this.relatedSlider.cells.length);
      bt.events.trigger('viewRendered');
    } else {
      bt.utility.hide($('.js-related-properties__wrapper'));
    }
  }

  photoGallery() {
    if (!this.useActiveTemplate()) {
      return;
    }
    const self = this;

    // {jquery ref} We display an index on the page, and cache a ref to it's location
    // init to falsey
    let $displayIndex = null;

    // {string}
    // - an internal counter we use to track what slide the user is on
    // - also used to determine if a selectCell event was actually a change
    //   in current cell
    this.displayIndex = '1';

    // Init gallery
    const $listingGallery = this.$('.js-listing-gallery');
    this.listingGallery = new Flickity($listingGallery[0], {
      cellSelector: '.bt-listing-gallery__cell',
      contain: true,
      percentPosition: false,
      lazyLoad: 2,
      pageDots: false,
      imagesLoaded: true,
      wrapAround: true,
      on: {
        ready: () => {
          // append photo count to gallery ie. "1 of 5", adding +1 for CTA slide
          // Should roughly match DOM found in `/components/Common/SlideCountPill`
          $listingGallery.append(`
            <span class='pill bg-black--transparent-50 bt-listing__slide-count-pill bt-listing__slide-count-pill--legacy'>
              <span class='current'>1</span> of <span class='total'>
                ${this.listing.attributes.PhotoCount + 1}
              </span>
            </span>
          `);

          // stash the dom node so we can update it later
          $displayIndex = $listingGallery.find('.current');

          // Scroll to the contact agent form
          // this is inside the photogallery code because the css in place doesn't hide
          // all of the slides, so the scroll position can't be trusted
          if (window.location.hash === '#listing-contact-agent-form') {
            self.loadContactAgentForm(null, self.contactForm.component.focusComments);
          }

          const urlParams = new URLSearchParams(window.location.search);
          const jumpTo = urlParams.get('jumpto');

          if (jumpTo === 'map') {
            this.scrollToPageElement('.js-map-column');
          }
          if (jumpTo === 'calculator') {
            this.scrollToPageElement('.js-mortgage-calculator');
          }
          if (jumpTo === 'contactagent') {
            self.loadContactAgentForm(null, self.contactForm.component.focusComments);
          }
        },
      },
    });

    // Init thumbnails
    const $listingThumbnails = this.$('.js-listing__thumbnails');
    this.listingThumbnails = new Flickity($listingThumbnails[0], {
      cellSelector: '.bt-card-slider-nav__cell',
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      freeScroll: true,
      accessibility: false,
    });

    // Nav clicks get routed to the main gallery
    this.listingThumbnails.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      if (typeof cellIndex === 'number') {
        this.listingGallery.select(cellIndex);
      }
    });

    // The cellSelect event occurs multiple times for more reasons than a cell being selected
    // - update view "displayIndex"
    // - squeeze the user
    this.listingGallery.on('cellSelect', () => {
      // CNS-3134 / CNS-3463 : we null out flickity instances on route change
      // which means that in slow browsers event listeners might have a race with
      // route changes
      if (!this.listingGallery || !this.listingThumbnails) {
        return;
      }

      // Compare against our external index to de-dupe events
      const displayIndex = (this.listingGallery.selectedIndex + 1).toString();
      if (this.displayIndex === displayIndex) {
        return;
      }

      this.displayIndex = displayIndex;

      // Update our view the easy way
      if ($displayIndex) {
        $displayIndex.html(displayIndex);
      }

      if (this.listingGallery.selectedIndex === this.listing.attributes.Photos.length) {
        window.requestAnimationFrame(() => {
          store.dispatch(a.showPhotoSliderCTA());
        });
      }

      // Update the nav slider
      this.listingThumbnails.select(this.listingGallery.selectedIndex);

      // If user needs to register
      if (bt.visitor.needsToRegister()) {
        const squeezeAfterNr = bt.config.photoSqueeze;

        if (this.listingGallery.selectedIndex >= squeezeAfterNr) {
          // Blur gallery so keydown listener doesn't work
          $listingGallery.blur();

          window.location.hash = 'reg=1';
          bt.app.squeezeForm(photoSliderData.urlParam, false, false);
        }
      }
    });
  }

  /**
   * @see {@link single/effects.js}
   * @param {Event} e
   */
  handleSearchTag(e) {
    e.preventDefault();
    store.dispatch(
      a.clickListingAttribute({
        name: $(e.currentTarget)
          .data('name')
          .toString(),
        id: $(e.currentTarget)
          .data('id')
          .toString(),
        type: $(e.currentTarget)
          .data('type')
          .toString(),
      })
    );
  }

  autocompleteAddress() {
    if (this.autocompleteDirectionsInit) {
      this.googleMapsPromise = bt.deps.loadGoogleMaps();
      this.googleMapsPromise.then(() => {
        const el = document.getElementsByClassName('js-listing__directions-from')[0];
        this.autocompleteDirectionsInit = false;
        this.autocompleteDirections = new window.google.maps.places.Autocomplete(el, {
          types: ['geocode'],
        });
        // If someone selects an address, go ahead and submit form
        this.autocompleteDirections.addListener('place_changed', () =>
          $('.js-btnGetDirections').trigger('click')
        );
      });
    }
  }

  loadEmailListing(e_) {
    if (e_ != null) {
      e_.preventDefault();
    }

    if (bt.visitor.isRegistered()) {
      $('.js-emailListingSubject').val(
        `${bt.tenant.legalName} - ${bt.listing.attributes.Location.FormattedAddress}`
      );
      $('.js-emailListingTitle').text('Email This Listing');

      bt.events.trigger('email-listing');

      // Recaptcha Plugin
      if (bt.config.recaptchaEnabled) {
        try {
          const recaptchaElement = document.querySelector('.js-email-recaptcha .bt-recaptcha-modal-v2');
          window.grecaptcha.render(recaptchaElement, {
            sitekey: bt.config.recaptchaKey,
            callback() {
              $('.js-email-recaptcha-error').hide();
            }
          });
        } catch {
          // Was already rendered, proceed- make sure we're clean.
          $('.js-email-recaptcha-error').hide();
          $('.js-email-recaptcha [name="g-recaptcha-response"]').val('');
        }
      } else {
        $('.js-email-recaptcha').hide();
      }
      // End Recaptcha
    } else {
      bt.app.squeezeForm(shareListingData.urlParam, true, false);
    }
  }

  /**
   * CNS-2448: Scroll the Share button fully into view on mobile devices
   * when the user clicks it. This helps make sure that the Share dropdown is more
   * viewable on smaller devices.
   */
  loadShareDropdown() {
    if (!bt.utility.MQ_Medium() && !this.$('.js-listing-share').hasClass('bt-active')) {
      const shareOffset = this.$('.bt-listing__header-cta--mobile').offset().top;
      bt.utility.scrollToPosition(shareOffset);
    }
  }

  /**
   * @param {Event} e_
   * @param {Function} cb
   */
  loadContactAgentForm(e_, cb) {
    if (e_ != null) {
      e_.preventDefault();
    }

    this.scrollToPageElement(
      '.jsx-listing-details-contact-form',
      cb || this.contactForm.component.setFocusOnFirstFieldOnForm
    );
  }

  loadRequestForm(e_) {
    this.loadContactAgentForm(e_);
    // Update the state of the ListingDetailsContactForm
    this.contactForm.component.savedNodes.ListingDetailsContactForm.setState(prevState =>
      _.extend({}, prevState, {
        formData: _.extend({}, prevState.formData, {
          requestVideoTour: false,
          requestShowing: true,
        })
      }),
    );
  }
  loadVideoRequestForm(e_) {
    this.loadContactAgentForm(e_);
    // Update the state of the ListingDetailsContactForm
    this.contactForm.component.savedNodes.ListingDetailsContactForm.setState(prevState =>
      _.extend({}, prevState, {
        formData: _.extend({}, prevState.formData, {
          requestShowing: false,
          requestVideoTour: true,
        })
      }),
    );
  }

  gettoday() {
    const d = new Date();

    const month = d.getMonth() + 1;
    const paddedMonth = month < 10 ? `0${month}` : month;

    const day = d.getDate();
    const paddedDay = day < 10 ? `0${day}` : day;

    return `${paddedMonth}.${paddedDay}.${d.getFullYear()}`;
  }

  signin(e) {
    e.preventDefault();
    bt.app.squeezeForm(registerData.urlParam, true, true);
  }

  signup(e) {
    e.preventDefault();
    bt.app.squeezeForm(registerData.urlParam, true, false);
  }

  handleFavoriteClick(e) {
    e.preventDefault();
    this.toggleFavorite(this.listing.id);
  }

  toggleFavorite(id) {
    bt.favorites.toggle(id);
  }

  /**
   * EC-2244, we DO want the user to still be able to make the call, but we also want to log it
   * @param {Event} e
   */
  logcall(e) {
    const listingID = this.listing.id;
    const phone = $(e.currentTarget)
      .attr('href')
      .replace(/\D/g, '');
    const agentID = this.listing.get('AgentForDisplay')._ID;

    const params = {
      m: 'logcall',
      phone,
      lid: listingID,
      agentID,
    };

    params.access_token = bt.config.token;

    // Use synchronous javascript for a sec...
    //
    // Note: don't try to use promises here, see jquery ticket here...
    // http://bugs.jquery.com/ticket/11013#comment:40
    $.ajax({
      dataType: 'jsonp',
      async: false,
      url:
        `${bt.config.apiUrl}/lc/1/mobile/?visitorID=${bt.visitor.attributes._ID}&callback=?`,
      data: params,
      success() {},
    });
  }

  logPrint() {
    if (this.logged) {
      return;
    }
    bt.events.trigger('logPrinting', this.listing.id);
    this.logged = true;
  }

  /**
   * Smooth-scroll down to map column
   * Change offset based on screen-size
   *
   * @param {Event} e
   */
  mapAnchor(e) {
    e.preventDefault();
    this.scrollToPageElement('.js-map-column');
  }

  maybeHideArrows(jqElement, numberOfCards) {
    const small = 480;
    const medium = 768;
    const large = 960;
    const parentWidth = jqElement.parent().width();

    let cardsVisible = 1;
    if (parentWidth > small) {
      cardsVisible = 2;
    }
    if (parentWidth > medium) {
      cardsVisible = 3;
    }
    if (parentWidth > large) {
      cardsVisible = 4;
    }

    if (numberOfCards > cardsVisible) {
      jqElement.removeClass('bt-card-slider__prev-next--hide');
    } else {
      jqElement.addClass('bt-card-slider__prev-next--hide');
    }
  }

  /**
   * CNS-1826 Generate Great Schools search link
   * Listen for "School Ratings & Info" link click event,
   * then formulate the greatschools.org search URL using either the
   * Lat and Long if they are not equal to 0, otherwise use the zipcode
   * like we have been doing.
   * 1. Verify longitude and latitude values are not undefined or equal to 0
   * 2. Create a searchQuery array and append to our basePath
   * 3. Create a new window with our search URL or reuse a previously opened window with the same name
   */
  greatSchoolsSearch(e) {
    e.preventDefault();

    let pagePath;
    const location = this.listing.attributes.Location;
    const coords = location.Coordinates;
    const basePath = '//www.greatschools.org/search/';
    const searchQuery = [];
    const isLatLonUndefined = _.isUndefined(coords.Latitude) || _.isUndefined(coords.Longitude);
    const doLatLonEqualZero = coords.Latitude === 0 || coords.Longitude === 0;

    // Ensure lat and lon exist in our coords object
    // Ensure lat and lon are not 0,0
    // If either of these are true, then use zipcode to search
    if (isLatLonUndefined || doLatLonEqualZero) {
      // nearbySearch.page performs a zipcodesearch by default and does not require lon/lat.
      // We're also limited on what we can actually pass on to the GreatSchools search without
      // the lat/lon values. If we try to pass distance or grade schools, they will be stripped
      // from the URL.
      pagePath = 'nearbySearch.page?';
      searchQuery.push(`zipCode=${location.PostalCode}`, 'locationType=postal_code');
    } else {
      // No need to check for HideAddress attribute since the FormattedAddress attribute will
      // automatically be updated to only include the City, State and Zip if HideAddress is true
      const useableAddress = encodeURIComponent(location.FormattedAddress);

      // If we have lon/lat, then we need to fill up the entire search query
      pagePath = 'search.page?';
      searchQuery.push(
        'distance=15',
        encodeURI('gradeLevels[]=e&gradeLevels[]=m&gradeLevels[]=h'),
        `lat=${coords.Latitude}`,
        `lon=${coords.Longitude}`,
        `city=${encodeURIComponent(location.City)}`,
        `state=${encodeURIComponent(location.State)}`,
        `locationSearchString=${useableAddress}`,
        'locationType=street_address',
        `normalizedAddress=${useableAddress}`,
      );
    }
    const greatSchoolsURL = basePath + pagePath + searchQuery.join('&');

    // CNS-8139 for unregistered users force the squeezeForm
    if (bt.visitor.isRegistered()) {
      window.open(greatSchoolsURL, '_blank');
    } else {
      bt.app.squeezeForm(shareListingData.urlParam, true, false);
    }
  }

  /**
   * Given an object holding a selector and properties for the root HTMLElement
   * and comp. instance, attempt to mount `MyComponent`, mutating the object
   * when successful.
   * @param {Object} compObj
   * @param {React.Component} MyComponent
   * @param {Object} props
   */
  mountComponent(compObj, MyComponent, props) {
    const foundNode = this.$(compObj.selector).get(0);
    if (foundNode != null) {
      compObj.component = ReactDOM.render(createElement(MyComponent, props), foundNode);
      compObj.node = foundNode;
    }
  }

  mountConnectedComponent(compObj, MyComponent, props) {
    const foundNode = this.$(compObj.selector).get(0);
    if (foundNode != null) {
      compObj.component = ReactDOM.render(
        <Provider store={store}>
          <MyComponent {...props} />
        </Provider>,
        foundNode
      );
      compObj.node = foundNode;
    }
  }

  /**
   * Unmount a component using its object and set the cached instance and
   * mount point to null.
   * @param  {Object} compObj
   */
  unmountComponent(compObj) {
    if (compObj.node != null) {
      ReactDOM.unmountComponentAtNode(compObj.node);
      compObj.node = compObj.component = null;
    }
  }

  maybeUnmountFlickity() {
    // `off` for any flickity internal listeners
    // doesn't call off for listeners we instantiate
    if (this.listingGallery) {
      this.listingGallery.destroy();
      this.listingGallery = null;
    }

    if (this.listingThumbnails) {
      this.listingThumbnails.destroy();
      this.listingThumbnails = null;
    }

    if (this.relatedSlider) {
      this.relatedSlider.destroy();
      this.relatedSlider = null;
    }
  }

  /**
   * Helper method for component mounting methods below.
   * @return {boolean}
   */
  isAgentSubdomain() {
    return bt.agent != null;
  }

  /**
   * Mount the OpenHouses React component on the Handelbars rendered Desktop Details Page
   */
  mountOpenHouses() {
    const openHouses = this.listing.get('OpenHouses');
    const openHouseText = this.listing.get('OpenHouseText');

    if (openHouses || openHouseText) {
      this.mountConnectedComponent(this.openHouses, OpenHouses, { openHouses, openHouseText });

      if (this.hasLiveVirtualOpenHouse) {
        // Not my favorite thing, but the element we're mounting to in the DOM has the
        // `.bt-sash__live-pill` class, so the `:before` pseudo element is rendering the red circle
        // even when the component isn't mounted. Adding the `uk-hidden` class until the component
        // needs to mount is sufficient for not until we've further Reactified this page.
        $('.bt-sash__live-pill').removeClass('uk-hidden');
        this.mountComponent(
          this.virtualOpenHouseLive,
          Pill,
          { bgColor: 'red', children: 'LIVE' }
        );
      }
    }
  }

  /**
   * These two methods are just imperative ways of mounting the React
   * components on this page. To update them, ensure that the correct data
   * sources have been updated, then call these functions.
   *
   * TODO: Describe their state in terms of a combination of local and global app state, and
   * then simply `connect()` them
   */
  mountContactForm() {
    this.mountComponent(this.contactForm, ContactAgent, {
      tenant: this.tenant,
      listing: this.listing.attributes,
      agent: this.listing.get('AgentForDisplay'),
      lender: bt.preferredLender,
      specialRules: this.listing.get('specialRules'),
      showMobilePhone: bt.rules.get('ShowMobile') || this.isAgentSubdomain() || bt.visitor.isRegistered(),
      visitorDetails: bt.visitorDetails,
      isRegistered: bt.visitor.isRegistered(),
    });
  }

  mountRepCards() {
    if (!bt.rules.get('HideAgentFromPropDesc')) {
      this.mountComponent(this.repCards, AgentLenderWrapper, {
        tenant: this.tenant,
        innerLogoUri: this.listing.get('InnerLogoUri'),
        agent: this.listing.get('AgentForDisplay'),
        lender: bt.preferredLender,
        specialRules: this.listing.get('specialRules'),
        visitorDetails: bt.visitorDetails,
        isRegistered: bt.visitor.isRegistered(),
        showMobilePhone:
          bt.rules.get('ShowMobile') || this.isAgentSubdomain() || bt.visitor.isRegistered(),
        showButton: true,
      });
    }
  }

  mountMortgageCalculator() {
    this.mountConnectedComponent(this.mortgageCalc, MortgageCalculator, {
      listPrice: this.listing.get('ListPrice'),
      isCanadian: bt.rules.get('ClientIsCanadian'),
    });
  }

  /**
   * This is the action area on the right hand side of the >= tablet views
   * [ go see it | start an offer ]
   * [ share | map | favorite ]
   */
  mountCallToActionButtons() {
    this.mountConnectedComponent(this.callToActions, ListingDetailsCallToActionButtons, {
      address: `${this.listing.get('Location').StreetNumber} ${
        this.listing.get('Location').StreetName
      }`,
      hideMap: !this.listing.get('ShowMap') || bt.visitor.isBot(),
      isSold: this.isSoldListing(),
      isComingSoon: this.isComingSoon(),
      onEmailListing: this.loadEmailListing,
      photo: this.listing.get('MainPhoto').FullUrl,
      social: true,
      url: this.listing.get('Url'),
      isFavorite: this.listing.isFavorite(),
      listingID: this.listing.id,
      listPrice: this.listing.get('ListPrice'),
      startOffer: showStartAnOffer(this.listing.toJSON()),
    });
  }

  /**
   * This is the "Start an offer" button on the slightly above mobile breakpoint.
   * We don't replace the whole bar on that view.
   */
  mountStartAnOfferButton() {
    this.mountConnectedComponent(this.startAnOfferButton, StartAnOfferButton, {
      startOffer: showStartAnOffer(this.listing.toJSON()),
      listingID: this.listing.id,
      listPrice: `$${this.listing.get('ListPriceFormatted')}`,
      address: this.listing.get('Location').FormattedAddress,
    });
  }

  /**
   * Get the agent that will be displayed on this listing details page. If we
   * are on an agent's subdomain, they take precedence, and this Promise is
   * resolved immediately. Otherwise, this will take a network request.
   *
   * @param {number} listingID
   * @return {Promise<Agent>}
   */
  getAgentForDisplay(listingID) {
    // `bt.agent` is instantiated in `run.js` and is already an Agent Backbone model.
    if (bt.agent) {
      return Promise.resolve(bt.agent);
    }
    return bt.api.getNextBuyerAgent(listingID).then(data => new Agent(data));
  }

  /**
   * @param {Event} e_
   */
  scrollToDatePicker(e_) {
    if (e_) {
      e_.preventDefault();
    }
    this.scrollToPageElement('.js-datepicker');
  }

  /**
   * Standardizes scrolling to elements on the Details page
   *
   * @param {string} selector
   * @param {function} cb
   */
  scrollToPageElement(selector, cb) {
    let elOffset;
    const selTop = this.$(selector).offset().top;
    const $headerCTAMob = this.$('.bt-listing__header-cta--mobile');

    /* eslint-disable */
    if (bt.utility.MQ_Medium()) {
      elOffset = selTop - this.$('.js-listing__header').outerHeight() - 15;
    } else if ($headerCTAMob.css('position') !== 'fixed') {
      elOffset = selTop - $headerCTAMob.outerHeight() * 2 - 16;
    } else {
      elOffset = selTop - $headerCTAMob.outerHeight() - 15;
    }
    /* eslint-enable */

    bt.utility.scrollToPosition(elOffset, cb);
  }

  bindMapSwitcher() {
    const switcher = this.$el.find('[data-uk-switcher]');
    UIkit.switcher(switcher, UIkit.Utils.options(switcher.attr('data-uk-switcher')));
  }

  activateDynamicMap(e) {
    e.preventDefault();
    if (this.listing.get('ShowMap')) {
      this.googleMapsPromise = bt.deps.loadGoogleMaps();
      this.googleMapsPromise.then(() => {
        this.loadMaps();
      });
    }
  }
}
