import { connect } from 'react-redux';

export default connect(
  ({ startAnOffer }) => ({
    address: startAnOffer.address,
    comments: startAnOffer.comments,
    email: startAnOffer.email,
    errors: startAnOffer.errors,
    fullName: startAnOffer.fullName,
    hasViewedProperty: startAnOffer.hasViewedProperty,
    helpWithFinancing: startAnOffer.helpWithFinancing,
    listingId: startAnOffer.listingId,
    listPrice: startAnOffer.listPrice,
    offerAmount: startAnOffer.offerAmount,
    phone: startAnOffer.phone,
    token: '',
  })
);
