import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'coreComponents';
import { PROP_DETAILS } from 'cypress_constants';
import StartAnOfferFormFields from './FormFields';
import withData from './withData';

export const FORM_ID = 'sao-form';

const StartAnOfferForm = (props) => {
  const { isSubmitting, handleSubmit } = props;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isSubmitting) {
      handleSubmit();
    }
  };

  // Since we're using the pattern [0-9]* to bring up the num pad on
  // iOS Safari, but here we have commas and the form is actually
  // being submitted.
  return (
    <form data-cy={PROP_DETAILS.START_OFFER_FORM} id={FORM_ID} onSubmit={onSubmit} noValidate>
      <StartAnOfferFormFields {...props} />

      {/* spam filter */}
      <Input
        id="contactFormSpamFilter"
        type="text"
        name="LastName"
        className="LastName"
        tabIndex="-1"
        hidden
      />
    </form>
  );
};

StartAnOfferForm.propTypes = {
  onChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

StartAnOfferForm.defaultProps = {
  isSubmitting: false,
};

export default withData(StartAnOfferForm);
