import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'coreComponents';
import cx from 'classnames';

const RadioButton = (props) => {
  const { className, dataCY, ...extraProps } = props;

  const classes = cx(['component__radio-button', className]);

  return <Radio className={classes} dataCY={dataCY} {...extraProps} />;
};

RadioButton.propTypes = {
  className: PropTypes.string,
  dataCY: PropTypes.string,
};

export default RadioButton;
