import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Cell } from 'components/core/Grid';
import Text from 'components/core/Text';
import { config } from 'BoomTown';
import { MORTGAGE_CALCULATOR } from 'cypress_constants';

const isMobileApp = config.isMobileApp;

let quoteLink = (
  <div className="mt-16 text-xs--center">
    <a href="/finance" className="at-get-quote">
      Wondering what the real terms of your loan could be?
      Click here for a <Text bold>FREE</Text> no-obligation rate quote.
    </a>
  </div>
);

if (isMobileApp) {
  quoteLink = null;
}

const MobileMortgageCalcResults = (props) => {
  const {
    principalAndInterest,
    monthlyTax,
    estimatedMonthlyPayment,
    principal,
  } = props;

  return (
    <div>
      <Grid gutters className="mt-16">
        <Cell xs={8}>Principal &amp; Interest:</Cell>
        <Cell xs={4} className="text-xs--right">
          <Text
            semibold
            className="at-interest-val"
            dataCY={MORTGAGE_CALCULATOR.RESULTS_PRINCIPAL_AND_INTEREST}
          >
            {principalAndInterest}
          </Text>
        </Cell>
        <Cell xs={8}>Taxes &amp; Insurance:</Cell>
        <Cell xs={4} className="text-xs--right">
          <Text semibold className="at-tax-val" dataCY={MORTGAGE_CALCULATOR.RESULTS_TAXES}>
            {monthlyTax}
          </Text>
        </Cell>
      </Grid>
      <hr />
      <Grid alignItems="center">
        <Cell xs={8}>
          <div className="bt-heading__h4">Est. Monthly Payment</div>
        </Cell>
        <Cell xs={4} className="text-xs--right">
          <div className="bt-heading__h2 bt-text--green" data-cy={MORTGAGE_CALCULATOR.RESULTS_MONTHLY_PAYMENT}>
            {estimatedMonthlyPayment}
          </div>
        </Cell>
        <Cell xs={6}>
          <Text muted>Loan Amount:</Text>
        </Cell>
        <Cell xs={6} className="text-xs--right">
          <Text muted dataCY={MORTGAGE_CALCULATOR.RESULTS_PRINCIPAL}>{principal}</Text>
        </Cell>
      </Grid>
      {quoteLink}
    </div>
  );
};

MobileMortgageCalcResults.propTypes = {
  monthlyTax: PropTypes.string,
  principalAndInterest: PropTypes.string,
  estimatedMonthlyPayment: PropTypes.string,
  principal: PropTypes.string,
};

export default MobileMortgageCalcResults;
