import PropTypes from 'prop-types';
import React from 'react';
import FAIcon from 'components/core/FAIcon';
import MenuList, { MenuListLink, MenuListEmailShareLink } from 'components/Common/MenuList';
import { PROP_DETAILS } from 'cypress_constants';

/**
 * Print the current Property Details page.
 * First force close the modal, then print the page.
 */
const printListing = () => {
  return window.print();
};

const ShareOptions = ({ address, photo, social, url }) => {
  const encodedURL = encodeURIComponent(url);
  const encodedPhoto = encodeURIComponent(photo);
  const encodedAddress = encodeURIComponent(address);

  const pinterestLink = `https://pinterest.com/pin/create/button/?url=${encodedURL}&media=${encodedPhoto}&description=${encodedAddress}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
  const twitterLink = `https://twitter.com/home?status=${encodeURIComponent(`Check out this property! ${url}`)}&media=${encodedPhoto}&description=${encodedAddress}`;

  const shareList = ([
    <MenuListLink
      key="pinterest"
      rel="nofollow noopener noreferrer"
      target="_blank"
      href={pinterestLink}
      title="Share on Pinterest"
    >
      <FAIcon icon="pinterest" type="brand" /> Share on Pinterest
    </MenuListLink>,
    <MenuListLink
      key="facebook"
      rel="nofollow noopener noreferrer"
      target="_blank"
      href={facebookLink}
      title="Share on Facebook"
    >
      <FAIcon icon="facebook" type="brand" /> Share on Facebook
    </MenuListLink>,
    <MenuListLink
      key="twitter"
      rel="nofollow noopener noreferrer"
      target="_blank"
      href={twitterLink}
      title="Share on Twitter"
    >
      <FAIcon icon="twitter" type="brand" /> Share on Twitter
    </MenuListLink>,
  ]);

  return (
    <MenuList>
      <MenuListEmailShareLink dataCY={PROP_DETAILS.SHARE_EMAIL} />
      {
        social ? shareList : null
      }
      <MenuListLink
        className="js-listing-print"
        onClick={printListing}
      >
        <FAIcon icon="print" type="regular" /> Print this listing
      </MenuListLink>
    </MenuList>
  );
};

ShareOptions.propTypes = {
  address: PropTypes.string,
  photo: PropTypes.string,
  social: PropTypes.bool,
  url: PropTypes.string,
};

export default ShareOptions;
