import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as StartAnOfferActions from 'actions/StartAnOfferActions';
import withModal from 'hoc/withModal';

import { Grid, Cell } from 'components/core/Grid';
import { PrimaryButton } from 'coreComponents';
import Alert from 'components/uikit-wrappers';
import FAIcon from 'components/core/FAIcon';
import StartAnOfferForm, { FORM_ID } from 'components/StartAnOffer';
import { PROP_DETAILS } from 'cypress_constants';

const Close = (props) => {
  const style = {
    position: 'absolute',
    right: '15px',
    top: '15px',
    width: '28px',
    height: '28px',
    cursor: 'pointer'
  };

  return <FAIcon icon="times" type="solid" style={style} {...props} />;
};

const StartAnOfferModal = props => {
  const { isSubmitting, hideStartAnOfferForm, submitState, ...rest } = props;
  return (
    <div>
      <div>
        <Close
          fill="#4a4a4a"
          width={28}
          height={28}
          onClick={e => {
            e.preventDefault();
            hideStartAnOfferForm();
          }}
        />
        <h2 className="mt-0">Start an Offer</h2>
        {submitState === 'error' && (
          <div className="mb-16">
            <Alert type="danger">
              We're sorry! Something went wrong.
            </Alert>
          </div>
        )}
        <div className="mb-16">
          Tell us about the offer you have in mind, and we'll contact you to discuss your offer and
          finalize the details. By submitting this starting offer, you are only submitting an
          initial request to start an official offer.
        </div>

        <StartAnOfferForm isSubmitting={isSubmitting} {...rest} />

        <Grid gutters xs="full" justifyContent="center">
          <Cell md={6}>
            <PrimaryButton
              type="submit"
              className="at-contact-form-submit at-submit-btn"
              form={FORM_ID}
              disabled={isSubmitting}
              width="full"
              dataCY={PROP_DETAILS.SO_SUBMIT}
            >
              {isSubmitting ? 'Sending...' : 'Submit'}
            </PrimaryButton>
          </Cell>
        </Grid>

      </div>
    </div>
  );
};

StartAnOfferModal.displayName = 'StartAnOfferForm';

StartAnOfferModal.propTypes = {
  hideStartAnOfferForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitState: PropTypes.string.isRequired,
};

export default compose(
  connect(
    ({ startAnOffer }) => ({
      submitState: startAnOffer.submitState,
      isSubmitting: startAnOffer.isSubmitting,
    }),
    StartAnOfferActions
  ),
  withModal({ maxWidth: 520, width: '100%', gutters: '24px' })
)(StartAnOfferModal);
