import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import AgentLender from './AgentLender';

/**
 * A business-card-like component showing an agent's headshot, name, title,
 * (e.g. "Sales Associate") phone links, and button to focus/open a contact
 * form.
 * @param {Object} props
 * Language note - the name 'AgentLenderWrapper' suggests that an Agent or a Lender will always
 * be present in this component, else it will not render. This is not the case. If neither an
 * agent nor a lender are present, the AgentLenderWrapper will still be rendered, but it will
 * contain a button prompting the visitor to "Ask a Question".
 */

export default function AgentLenderWrapper(props) {
  const {
    agent,
    lender,
    specialRules,
    layout,
    className,
    visitorDetails,
    isRegistered
  } = props;

  // Don't render a container at all if neither entity is passed
  if (!agent && !lender) {
    return null;
  }

  if (isRegistered && !visitorDetails.BuyerAgentID) {
    return null;
  }

  const agentInfo = (() => {
    if (agent) {
      return (
        <AgentLender
          rep={agent}
          hasLender={Boolean(lender)}
          {...props}
          visitorDetails={visitorDetails}
        />
      );
    }
    return null;
  })();

  const lenderInfo = (() => {
    if (lender && !specialRules.HideAgentFromRequest) {
      return (
        <AgentLender
          rep={lender}
          {...props}
          isLender
          hasLender
        />
      );
    }
    return null;
  })();

  const wrapperStyles = cx({
    'grid grid--gutters grid-xs--full ': layout === 'full',
    'grid-md--halves grid-lg--full': layout === 'full' && Boolean(lender)
  });

  return (
    <div
      className={cx(
        className,
        { 'bt-panel uk-panel uk-panel-box': layout === 'full' }
      )}
    >
      <div className={wrapperStyles}>
        {agentInfo}
        {lenderInfo}
      </div>
    </div>
  );
}

AgentLenderWrapper.displayName = 'AgentLenderWrapper';
AgentLenderWrapper.propTypes = {
  agent: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    FullName: PropTypes.string,
    Title: PropTypes.string,
    Slug: PropTypes.string,
    OfficePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    MobilePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    CompanyName: PropTypes.string,
    LogoUrl: PropTypes.string,
    Address1: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    DRENumber: PropTypes.string
  }),
  lender:  PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    FullName: PropTypes.string,
    Title: PropTypes.string,
    Slug: PropTypes.string,
    OfficePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    MobilePhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    CompanyName: PropTypes.string,
    LogoUrl: PropTypes.string,
    Street: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),
  tenant: PropTypes.shape({
    alias: PropTypes.string,
    legalName: PropTypes.string,
    isAgentSubdomain: PropTypes.bool.isRequired,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  specialRules: PropTypes.shape({
    HideAgentFromRequest: PropTypes.bool,
    HideContactMention: PropTypes.bool
  }),
  visitorDetails: PropTypes.shape({
    BuyerAgentID: PropTypes.number
  }),
  isRegistered: PropTypes.bool,
  className: PropTypes.string,
  layout: PropTypes.oneOf([
    'Full',
    'full',
    'Card',
    'card'
  ])
};

AgentLenderWrapper.defaultProps = {
  layout: 'card',
};
