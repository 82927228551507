import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { config } from 'BoomTown';
import * as MortgageCalculatorActions from 'actions/MortgageCalculatorActions';
import {
  calculateMortgage,
  calcDownPaymentAmount,
  calcDownPaymentPercent,
  calcTaxAndInsAmount,
  calcTaxAndInsPercent,
} from 'utility/mortgageCalculator';
import { toDollarString, toDollarNum, toPercentage } from 'utility/string';
import MobileMortgageCalcForm from './form';


class MobileMortgageCalc extends Component {
  constructor(props) {
    super(props);
    const isCanadian = props.isCanadian;

    let listPrice = props.listPrice;

    let percentDown = toPercentage('20.0');

    let downPayment = calcDownPaymentAmount(listPrice, percentDown);

    const interestRate = isCanadian ? toPercentage(config.interestRateCA) : toPercentage(config.interestRateUS);

    let taxAndInsPercent = toPercentage('1.3');

    let taxAndInsAmount = calcTaxAndInsAmount(listPrice, taxAndInsPercent);

    const loanType = isCanadian ? '20' : '30';

    this.state = {
      listPrice,
      percentDown,
      downPayment,
      interestRate,
      taxAndInsPercent,
      taxAndInsAmount,
      loanType,
      previousValue: {
        downPayment,
        percentDown,
        taxAndInsAmount,
        taxAndInsPercent,
      },
    };

    /**
     * Create an object of key/value pairs where the key is the name of the input field, and
     * the value is a blurHandler fn specific to that input.
     *
     * There are multiple sets of input fields that depend on the values of the other. Since we
     * update the values of the fields in our state as the user types, we have to store an initial
     * value of these fields in the `previousValue` object in our state for comparison. This is
     * important when a $ value is changed by the user, automatically updating the corresponding
     * % value. However, often the % is truncated and not the full % value from the calculations.
     * If the user "blurs" on this truncated % field, the $ field would be updated to reflect the
     * actual value of that %. This could be jarring to the user and a poor UX.
     *
     * Ex.) listPrice: 600,000. User enter downPayment $ of 20,000.
     * The actual % value is 3.3333333333333335, but we display 3.3 instead. 3.3% of 600,000 is
     * actually 19,800, not 20,000
     */
    this.blurHandlers = {
      listPrice: value => {
        listPrice = toDollarNum(value);
        downPayment = calcDownPaymentAmount(listPrice, this.state.percentDown);
        this.setState({ listPrice, downPayment }, this.changeCallback);
      },
      downPayment: value => {
        downPayment = toDollarNum(value);
        listPrice = toDollarNum(this.state.listPrice);

        if (downPayment > listPrice) {
          downPayment = listPrice;
        }

        percentDown = toPercentage(calcDownPaymentPercent(listPrice, downPayment).toFixed(1));
        this.setState(
          ({ previousValue }) => ({
            downPayment,
            percentDown,
            previousValue: {
              ...previousValue,
              downPayment,
              percentDown,
            },
          }),
          this.changeCallback
        );
      },
      percentDown: e => {
        const { value } = e.target;
        percentDown = toPercentage(value);
        if (percentDown === this.state.previousValue.percentDown) return;

        listPrice = toDollarNum(this.state.listPrice);
        downPayment = calcDownPaymentAmount(listPrice, percentDown);
        this.setState(
          ({ previousValue }) => ({
            downPayment,
            percentDown,
            previousValue: {
              ...previousValue,
              downPayment,
              percentDown,
            },
          }),
          this.changeCallback
        );
      },
      loanType: e => this.handleOnChange(e),
      interestRate: e => {
        const { value } = e.target;
        this.setState({ interestRate: toPercentage(value) }, this.changeCallback);
      },
      taxAndInsAmount: value => {
        taxAndInsAmount = toDollarNum(value);
        listPrice = toDollarNum(this.state.listPrice);

        if (taxAndInsAmount > listPrice) {
          taxAndInsAmount = listPrice;
        }

        taxAndInsPercent = toPercentage(calcTaxAndInsPercent(listPrice, taxAndInsAmount));
        this.setState(
          ({ previousValue }) => ({
            taxAndInsAmount,
            taxAndInsPercent,
            previousValue: {
              ...previousValue,
              taxAndInsAmount,
              taxAndInsPercent,
            },
          }),
          this.changeCallback
        );
      },
      taxAndInsPercent: e => {
        const { value } = e.target;
        taxAndInsPercent = toPercentage(value);
        if (taxAndInsPercent === this.state.previousValue.taxAndInsPercent) return;

        listPrice = toDollarNum(this.state.listPrice);
        taxAndInsAmount = calcTaxAndInsAmount(listPrice, taxAndInsPercent);
        this.setState(
          ({ previousValue }) => ({
            taxAndInsAmount,
            taxAndInsPercent,
            previousValue: {
              ...previousValue,
              taxAndInsAmount,
              taxAndInsPercent,
            },
          }),
          this.changeCallback
        );
      },
    };
  }

  changeCallback = () => this.props.onChange(this.state);

  handleOnChange = e => this.setState({ [e.target.name]: e.target.value }, this.changeCallback);

  render() {
    /* eslint-disable indent */
    const loanTypeOptions = this.props.isCanadian
      ? ['20', '25', '30'].map(y => ({ value: y, label: `${y} Years` }))
      : [
          { value: '30', label: '30 Year Fixed' },
          { value: '15', label: '15 Year Fixed' },
          { value: '5', label: '5/1 ARM' },
        ];
    /* eslint-enable indent */

    const duration =
      this.state.loanType === '5' ? 5 * 6 * 12 : parseInt(this.state.loanType, 10) * 12;

    const { principal, perMonthNoTax, perMonthAndTax, monthlyTax } = calculateMortgage(
      this.state.listPrice,
      this.state.interestRate,
      duration,
      this.state.percentDown,
      this.state.taxAndInsAmount
    );

    return (
      <MobileMortgageCalcForm
        listPrice={this.state.listPrice}
        downPayment={this.state.downPayment}
        percentDown={this.state.percentDown}
        loanType={this.state.loanType}
        loanTypeOptions={loanTypeOptions}
        interestRate={this.state.interestRate}
        interestRateHelper={this.props.isCanadian ? 'Rates Vary' : null}
        displaytaxAndIns={!this.props.isCanadian}
        taxAndInsAmount={this.state.taxAndInsAmount}
        taxAndInsPercent={this.state.taxAndInsPercent}
        monthlyTax={toDollarString(monthlyTax)}
        principalAndInterest={toDollarString(perMonthNoTax)}
        estimatedMonthlyPayment={toDollarString(perMonthAndTax)}
        principal={toDollarString(principal)}
        handleOnBlur={this.blurHandlers}
        handleOnChange={this.handleOnChange}
      />
    );
  }
}

MobileMortgageCalc.displayName = 'MortgageCalculator';

MobileMortgageCalc.propTypes = {
  listPrice: PropTypes.number.isRequired,
  isCanadian: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

MobileMortgageCalc.defaultProps = {
  onChange: () => {},
};

export default connect(null, MortgageCalculatorActions)(MobileMortgageCalc);
