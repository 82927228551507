import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Grid, Cell } from 'components/core/Grid';
import Address from 'components/DetailsPage/AgentLenderWrapper/Address';

const BrandID = (props) => {
  const {
    rep,
    innerLogoUri,
    tenant,
    name,
    isLender,
    hideAgent
  } = props;

  return (
    <Grid
      gutters
      justifyContent="between"
      alignItems="center"
      className={cx({ 'mt-4': !hideAgent, 'mt-0': hideAgent })}
      itemType="http://schema.org/Organization"
      itemScope
      itemProp="worksFor"
    >
      <Cell xs={5} lg={4} xl={6}>
        {innerLogoUri && (
          <img
            className="bt-listing__agent__brandid__logo"
            alt={name}
            src={innerLogoUri}
            title={name}
            itemProp="logo"
          />
        )}
      </Cell>
      <Cell className="m-0">
        <Address
          name={name}
          rep={rep}
          tenant={tenant}
          isLender={isLender}
        />
      </Cell>
    </Grid>
  );
};

BrandID.displayName = 'BrandID';
BrandID.propTypes = {
  rep: PropTypes.shape({
    Address1: PropTypes.string,
    Street: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  innerLogoUri: PropTypes.string,
  tenant: PropTypes.shape({
    isAgentSubdomain: PropTypes.bool.isRequired,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  isLender: PropTypes.bool,
  hideAgent: PropTypes.bool
};

export default BrandID;
