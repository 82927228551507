var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-xs--center\">\n	<div class=\"bt-heading__h2 js-emailListingTitle mb-16\">Email This Listing</div>\n	<div>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"FormattedAddress") : stack1), depth0))
    + "</div>\n	<h3 class=\"uk-text-bold uk-text-success mt-0\">$"
    + alias2(((helper = (helper = lookupProperty(helpers,"ListPriceFormatted") || (depth0 != null ? lookupProperty(depth0,"ListPriceFormatted") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"ListPriceFormatted","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":70}}}) : helper)))
    + "</h3>\n</div>\n\n<form id=\"email-listing-form\" data-parsley-validate>\n  <div class=\"form__group\">\n    <div class=\"form__label-group\">\n      <label class=\"form__label\" for=\"toname\">Recipient Name</label>\n      <span class=\"form__required-text\" aria-label=\"required\" title=\"required\">*</span>\n    </div>\n    <input\n      name=\"toname\"\n      id=\"toname\"\n      data-cy=\"prop-details__share-recipient-name\"\n      class=\"form__input at-fullName-txt\"\n      data-parsley-required-message=\"Enter Recipient's Name\"\n      required\n    />\n  </div>\n\n  <div class=\"form__group\">\n    <div class=\"form__label-group\">\n      <label class=\"form__label\" for=\"toaddress\">Recipient Email</label>\n      <span class=\"form__required-text\" aria-label=\"required\" title=\"required\">*</span>\n    </div>\n    <input\n      type=\"email\"\n      name=\"toaddress\"\n      id=\"toaddress\"\n      data-cy=\"prop-details__share-recipient-email\"\n      class=\"form__input at-email-txt\"\n      data-parsley-required-message=\"Enter Recipient's Email\"\n      required\n    />\n  </div>\n\n  <div class=\"form__group\">\n    <div class=\"form__label-group\">\n      <label class=\"form__label\" for=\"emailSubject\">Email Subject</label>\n      <span class=\"form__required-text\" aria-label=\"required\" title=\"required\">*</span>\n    </div>\n    <input\n      name=\"subject\"\n      id=\"emailSubject\"\n      class=\"form__input js-emailListingSubject\"\n      value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Agent") : depth0)) != null ? lookupProperty(stack1,"OfficeName") : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"FormattedAddress") : stack1), depth0))
    + "\"\n      data-parsley-required-message=\"Enter Recipient's Email\"\n      required\n    />\n  </div>\n\n  <div class=\"form__group\">\n    <div class=\"form__label-group\">\n      <label class=\"form__label\" for=\"emailBody\">Email Body</label>\n    </div>\n    <textarea\n      id=\"emailBody\"\n      class=\"form__textarea\"\n      cols=\"30\"\n      rows=\"5\"\n      maxlength=\"200\"\n      readonly=\"readonly\"\n      disabled=\"disabled\"\n    >Take a look at this property I found on "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Tenant") : depth0)) != null ? lookupProperty(stack1,"Website") : stack1), depth0))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"Url") || (depth0 != null ? lookupProperty(depth0,"Url") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"Url","hash":{},"data":data,"loc":{"start":{"line":66,"column":65},"end":{"line":66,"column":72}}}) : helper)))
    + "</textarea>\n  </div>\n\n  <div class=\"form__group\">\n    <div class=\"form__label-group\">\n      <label class=\"form__label\" for=\"emailComments\">Additional Comments</label>\n    </div>\n    <textarea\n      id=\"emailComments\"\n      name=\"comments\"\n      class=\"form__textarea js-emailListingComments at-share-email-form at-comment-txt\"\n      data-cy=\"prop-details__share-email-body\"\n      cols=\"30\"\n      rows=\"5\"\n      maxlength=\"200\"\n    ></textarea>\n  </div>\n\n	<div class=\"form__check--group\">\n    <div class=\"form__check\">\n      <input\n        type=\"checkbox\"\n        id=\"ccme\"\n        name=\"ccme\"\n        class=\"form__check-input\"\n      />\n      <label class=\"form__check-label\" for=\"ccme\">\n          <span class=\"form__check-label__text\">Send Copy of Message to Me</span>\n      </label>\n    </div>\n	</div>\n\n	<div class=\"btgf-field\">\n    <div class=\"form__group\">\n      <div class=\"bt-recaptcha-modal-v2\"></div>\n      <span class='js-email-recaptcha-error parsley-errors-list filled' style='display:none'>\n        A recaptcha response is required.\n      </span>\n    </div>\n	</div>\n\n	<div class=\"grid grid--gutters grid-xs--full\">\n		<div class=\"cell text-xs--center mt-4\">\n			<input type=\"hidden\" name=\"LastName\" class=\"LastName\" aria-label=\"hidden field\">\n			<button class=\"btn btn--primary  js-emailListing at-submit-btn\" data-cy=\"prop-details__share-send-email\">Send Email</button>\n		</div>\n	</div>\n</form>\n";
},"useData":true});