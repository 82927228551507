import { connect } from 'react-redux';
import { getIsRegistered } from 'models/visitor/reducers/selectors';
import OpenHouseCard from './component';

export default connect(
  (state) => ({
    visitorRegistered: getIsRegistered(state),
  }),
  null
)(OpenHouseCard);
