import PropTypes from 'prop-types';
import React from 'react';
import defer from 'components/Common/FormElements/deferCheckedOnChange';

const Switch = ({ checked, disabled = false, label, className = '', onChange = () => {} }) => (
  <label htmlFor="power-search-switch" className={`form__switch__label ${className}`}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      id="power-search-switch"
      className="form__switch__input--hidden"
    />
    <span className="form__switch__knob" />
    <div className="form__switch__track" />
    {Boolean(label) && <span className="ml-4">{label}</span>}
  </label>
);

Switch.propTypes = {
  large: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default defer(Switch);
