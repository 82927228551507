/**
 * Calculate a monthly mortgage payment.
 * @param  {Number} price            The list price of the home.
 * @param  {Number} interestRate     Yearly interest rate in percent.
 * @param  {Number} duration         the duration of the loan in months.
 * @param  {Number} percentDown      Portion of the price paid as a down payment, in percent.
 * @param  {Number} taxAndIns        Tax/insurance, as a dollar.
 * @return {Object}                  An object with the principal loan amount, estimated monthly payment including tax, and the monthly tax amount
 */
export const calculateMortgage = (price, interestRate, duration, percentDown, taxAndIns) => {
  const amountDown = price * (percentDown / 100);
  const principal = Math.round(price - amountDown);
  const monthlyInterestRate = interestRate / (100 * 12);

  // https://www.nerdwallet.com/mortgages/mortgage-calculator/calculate-mortgage-payment
  // https://usmortgagecalculator.org/
  // https://stackoverflow.com/questions/17101442/how-to-calculate-mortgage-in-javascript
  // const principalAndInterest = Math.round((p * i * Math.pow(i + 1, n)) / (Math.pow(i + 1, n) - 1));
  const perMonth = Math.round(
    (principal * monthlyInterestRate) /
    (1 - (1 / Math.pow(1 + monthlyInterestRate, duration)))
  );

  let monthlyTax = taxAndIns;
  if (typeof monthlyTax !== 'number') {
    monthlyTax = parseInt(monthlyTax, 10);
  }

  return {
    principal,
    perMonthNoTax: perMonth,
    perMonthAndTax: perMonth + monthlyTax,
    monthlyTax
  };
};

/**
 * Calculate the dollar value for a Down Payment
 * @param {Number} price      The list price of the home
 * @param {Number} percent    The down payment percent value
 * @return {Number}           Down payment dollar value
 */
export const calcDownPaymentAmount = (price, percent) => Math.round(price * (percent / 100));

/**
 * Calculate the percentage value for a Down Payment
 * @param {Number} price        The list price of the home
 * @param {Number} amount       The down payment dollar value
 * @return {Number}             Down payment percent value
 */
export const calcDownPaymentPercent = (price, amount) => ((amount / price) * 100);

/**
 * Calculate the dollar value for Monthly Tax and Insurance
 * @param {Number} price      The list price of the home
 * @param {Number} percent    The monthly tax and insurance percent value
 * @return {Number}           Tax and Insurance dollar value
 */
export const calcTaxAndInsAmount = (price, percent) => Math.round((price * percent) / (100 * 12));

/**
 * Calculate the percentage value for Monthly Tax and Insurance
 * @param {Number} price        The list price of the home
 * @param {Number} amount       The monthly tax and insurance dollar value
 * @return {Number}             Tax and Insurance percent value
 */
export const calcTaxAndInsPercent = (price, amount) => ((amount / price) * (100 * 12));
