import PropTypes from 'prop-types';
import React, { Component } from 'react';
import emptyFunction from 'utility/emptyFunction';

export default function deferCheckedOnChange(InputComponent) {
  class DeferCheckedOnChange extends Component {
    constructor(props) {
      super(props);
      this.state = {
        checked: this.props.checked
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        checked: nextProps.checked
      });
    }

    /**
     * This addresses issues with `onChange` handlers that are synchronous and
     * very expensive, like initiating a route change. We want the handler to
     * be called _after_ the next paint, and while it looks a little weird,
     * this seems to be the most effective way to accomplish this. It's
     * ultimately up to the browser to decide how and when to process
     * functions in the animation frame request callback list, but if the list
     * is being processed, it's not likely to be revisited until at least the
     * next frame.
     * TODO: Try batchedUpdates for this.
     */
    _handleChange = e => {
      this.setState((prevState) => ({
        checked: !prevState.checked
      }));
      e.persist();
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          this.props.onChange(e);
        });
      });
    };

    render() {
      return (
        <InputComponent
          {...this.props}
          onChange={this._handleChange}
          checked={this.state.checked}
        />
      );
    }
  }

  DeferCheckedOnChange.defaultProps = {
    onChange: emptyFunction
  };
  DeferCheckedOnChange.propTypes = {
    ...InputComponent.propTypes,
    onChange: PropTypes.func,
    checked: PropTypes.bool
  };

  return DeferCheckedOnChange;
}
