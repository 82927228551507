import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

import AgentLenderWrapper from 'components/DetailsPage/AgentLenderWrapper';
import ContactFormDisclaimer from 'components/Common/ContactFormDisclaimer';
import ListingDetailsContactForm from './Form';

export default class ContactAgent extends Component {
  constructor(props) {
    super(props);
    this.savedNodes = {};
  }

  setFocusOnFirstFieldOnForm = () => {
    if (this.savedNodes.ListingDetailsContactForm) {
      this.savedNodes.ListingDetailsContactForm.setFocusOnFirstFieldOnForm();
    }
  }

  /**
   * Focus on the comments field. Invoked publicly from `single.js`.
   */
  focusComments = () => {
    if (this.savedNodes.ListingDetailsContactForm.commentsField) {
      this.savedNodes.ListingDetailsContactForm.commentsField.focus();
    }
  }

  render() {
    const {
      agent,
      lender,
      listing,
      tenant,
      showMobilePhone,
      specialRules,
      visitorDetails,
      isRegistered
    } = this.props;

    let titleText = 'Ask a Question';

    // Only show the agent's name if they aren't hidden from request,
    // and if they are a buyer agent
    // if no lender object exists, and if the agent has a first name to display.
    if (!specialRules.HideAgentFromRequest && !lender && agent && agent.FirstName && visitorDetails.BuyerAgentID) {
      titleText = `Ask ${agent.FirstName} a Question`;
    }

    const agentLenderClasses = cx(
      'cell cell-lg-4',
      {
        'cell-md-6': !lender
      }
    );

    return (
      <div className="js-listing-contact-agent-widget">
        <div className="grid grid--gutters grid--justifyCenter grid-xs--full">
          <div className="cell cell-lg-8">
            <div className="bt-panel uk-panel uk-panel-box overflow-show">
              <div className="bt-listing__agent__contact-widget__form">
                <h3 className="bt-listing__headline bt-heading__h3">
                  {titleText}
                </h3>
                <div id="ask-a-question-form" className="jsx-listing-details-contact-form">
                  <ListingDetailsContactForm
                    listingID={listing._ID}
                    ref={(node) => (this.savedNodes.ListingDetailsContactForm = node)}
                    listing={listing}
                  />
                </div>
                <ContactFormDisclaimer />
              </div>
            </div>
          </div>
          <div className={agentLenderClasses}>
            <AgentLenderWrapper
              className="bt-listing__agent__contact-widget__card"
              agent={agent}
              tenant={tenant}
              lender={lender}
              innerLogoUri={listing.InnerLogoUri}
              showMobilePhone={showMobilePhone}
              specialRules={specialRules}
              showBranding
              layout="full"
              visitorDetails={visitorDetails}
              isRegistered={isRegistered}
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactAgent.displayName = 'ContactAgent';
ContactAgent.propTypes = {
  agent: PropTypes.object,
  lender: PropTypes.object,
  listing: PropTypes.shape({
    _ID: PropTypes.number,
    InnerLogoUri: PropTypes.string
  }).isRequired,
  specialRules: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  showMobilePhone: PropTypes.bool,
  visitorDetails: PropTypes.object,
  isRegistered: PropTypes.bool
};
