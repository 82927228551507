import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as StartAnOfferActions from 'actions/StartAnOfferActions';
import { PrimaryButton } from 'coreComponents';

const StartAnOfferButton = ({
  showStartAnOfferForm,
  startOffer,
  listingID,
  listPrice,
  address
}) => {
  if (startOffer) {
    return (
      <div>
        <PrimaryButton
          className="at-start-an-offer-btn bt-listing__header-cta__btn js-start-offer-showing"
          onClick={() => {
            showStartAnOfferForm({ listingId: listingID, listPrice, address });
          }}
          title="Start an Offer"
          width="full"
        >
          Start an Offer
        </PrimaryButton>
      </div>
    );
  }

  return (
    <button type="button" className="at-contact-btn bt-listing__header-cta__btn width-1-1 btn-primary js-listing-contact-agent">
      Contact Agent
    </button>
  );
};

StartAnOfferButton.propTypes = {
  hideStartAnOfferForm: PropTypes.func,
  showStartAnOfferForm: PropTypes.func,
  startOffer: PropTypes.bool,
  startAnOfferIsOpen: PropTypes.bool,
  listingID: PropTypes.number,
  listPrice: PropTypes.string,
  address: PropTypes.string
};

StartAnOfferButton.defaultProps = {
  startOffer: false,
  startAnOfferIsOpen: false,
  listingID: null,
  address: null,
  listPrice: '',
};

export default connect(
  (state) => ({
    startAnOfferIsOpen: state.startAnOffer.isOpen,
  }),
  StartAnOfferActions
)(StartAnOfferButton);
