import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { account } from 'BoomTown';
import { formatDateTime, PARSE_FORMATS } from 'utility/dateTime';
import { Grid, Cell } from 'components/core/Grid';
import FAIcon from 'components/core/FAIcon';
import { PROP_DETAILS } from 'cypress_constants';

const formatOpts = { parseFormat: PARSE_FORMATS, timezone: account.get('TimeZoneID') };

/**
 * Component to manage rendering the dates and times for OpenHouse Cards
 *
 * @param {Object} props
 * @param {string} props.start
 * @param {string} props.end
 */
export default function OpenHouseInfo(props) {
  const { start, end, showVirtualUrl, url } = props;
  if (!start) {
    return null;
  }

  const { date: ohDate, time: ohTime } = useMemo(() => {
    let date;
    let time;

    const startObj = formatDateTime(start, formatOpts);
    const endObj = formatDateTime(end, formatOpts);

    // If `startObj` is the same as it started, then the date string was invalid. Return what we
    // have available.
    if (startObj === start) {
      date = start;

      if (endObj && endObj === end && date !== end) {
        date = `${date} - ${end}`;
      }

      return { date };
    }

    //
    // DATE
    //
    if (startObj && startObj.date) {
      date = startObj.date;
    }

    if (endObj && endObj.date) {
      if (date !== endObj.date) {
        date = `${date} - ${endObj.date}`;
      }
    }

    //
    // TIME
    //
    if (startObj && startObj.time) {
      time = startObj.time;
    }

    if (endObj && endObj.time) {
      if (time !== endObj.time) {
        time = `${time} - ${endObj.time}`;
      }
    }

    return { date, time };
  }, [start, end]);

  return (
    <Grid gutters className="mt-12">
      {Boolean(ohDate) && (
        <Cell noGrow>
          <FAIcon icon="calendar-day" type="solid" />
          <span className="ml-4">{ohDate}</span>
        </Cell>
      )}

      {Boolean(ohTime) && (
        <Cell noGrow>
          <FAIcon icon="clock" type="solid" />
          <span className="ml-4">{ohTime}</span>
        </Cell>
      )}

      {showVirtualUrl && (
        <Cell noGrow>
          <a
            href={url}
            className="gtm-virtual-open-house-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FAIcon className="gtm-virtual-open-house-link" icon="video" type="solid" />
            <span
              className="gtm-virtual-open-house-link ml-4"
              data-cy={PROP_DETAILS.VIRTUAL_OPEN_HOUSE_VIEW_HERE}
            >
              View Here
            </span>
          </a>
        </Cell>
      )}
    </Grid>
  );
}
OpenHouseInfo.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  showVirtualUrl: PropTypes.bool,
  url: PropTypes.string,
};
