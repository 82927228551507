import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment-timezone';
import { isSold } from 'utility';
import validator from 'utility/validator';
import RecaptchaV2 from 'components/Common/FormElements/RecaptchaV2';
import {
  agent,
  config,
  utility as globalUtility,
  /**
   * In the future, this will be passed in via props, but we need it for things
   * like the `contactForm` method and `IsRegistered`, which can change value
   * during a single render.
   */
  visitor as visitorModel,
} from 'BoomTown';
import { PROP_DETAILS, PARSLEY } from 'cypress_constants';
import { Checkbox, DatePicker, Input, PrimaryButton, Select, TextArea } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import Alert from 'components/uikit-wrappers';

/**
 * CNS-7726 :This helps get the correct again for the contact form based upon subdomain context or assignment.
 * @return {string}
 */
const getAgentId = () => {
  try {
    return agent.attributes._ID;
  } catch (e) {
    return '';
  }
};

/**
 * This is the only thing that's keeping this Component from being defined
 * using the `createContactForm` HOC fn.
 */
const getValidationRules = (state) => {
  const rules = {
    firstName: {
      isValid: validator.isValidName,
      message: 'Enter a valid first name.',
    },
    lastName: {
      isValid: validator.isValidName,
      message: 'Enter a valid last name.',
    },
    email: {
      isValid: validator.isEmail,
      message: 'Please enter a valid email address.',
    },
    phone: {
      isValid: validator.isPhone,
      message: 'Please enter a valid phone.',
    },
    comments: {
      isValid: validator.isNotWhitespace,
      message: 'Please enter a question before submitting the form.',
    },
  };

  if (state && state.formData && state.formData.requestShowing) {
    rules.showingDate = {
      isValid: validator.isNotFalsyOrWhitespace,
      message: 'Please enter a date.',
    };
  }

  if (config.recaptchaEnabled) {
    rules.token = {
      isValid: validator.isNotWhitespace,
      message: 'Invalid Recaptcha Response',
    };
  }

  return rules;
};

class ListingDetailsContactForm extends Component {
  constructor(props) {
    super(props);

    const visitorGetNotDefault = (prop, def) => {
      const val = visitorModel.get(prop);
      if (val && val !== def) {
        return val;
      }
      return '';
    };
    // CNS-6735
    this.state = {
      renderForm: true, // false after submission to hide the form
      formData: {
        requestShowing: false,
        requestVideoTour: false,
        showingDate: moment(),
        showingTime: 'Anytime',
        firstName: visitorGetNotDefault('FirstName', 'Guest'),
        lastName: visitorGetNotDefault('LastName', 'Visitor'),
        email: visitorModel.get('Username') || '',
        phone: visitorModel.get('BestPhone'),
        comments: `I am interested in ${props.listing.Location.FormattedAddress}.`,
      },
    };
  }

  setFocusOnFirstFieldOnForm() {
    if (this.firstNameField) {
      this.firstNameField.focus();
    }
  }

  _returnFirstNameRef = (ref) => {
    this.firstNameField = ref;
  };

  _returnRequestShowingRef = (ref) => {
    this.requestShowingRef = ref;
  };
  // CNS-6735
  _returnRequestVideoTourRef = (ref) => {
    this.requestVideoTourRef = ref;
  };

  _returnCommentsRef = (ref) => {
    this.commentsField = ref;
  };

  /**
   * Submit GA or GTM tracking data when the form is submitted. Also modifies
   * the contact type cookie if not already set to 'lead'.
   * @param  {Boolean} reqShowing
   * @todo Refactor into some kind of middleware that responds to actions once
   *     we're in a Flux environment.
   */
  _submitTrackingData = (trackingParams) => {
    if (window.dataLayer) {
      window.dataLayer.push(trackingParams);
    }
  };

  /**
   * Get a unique ID for a field. Note: assumes that only one instance of this
   * component will be rendered.
   */
  _fieldID = (fieldName) => `${ListingDetailsContactForm.displayName}__${fieldName}`;

  /**
   * Update state when checkboxes' values change. (Identical to
   * `handleFieldChange()` except with different state via DOM API, i.e.
   * value vs. checked.)
   * @param  {string} key The field's key on `this.state`
   * @param  {Event} e
   */
  handleCheckboxChange = (key, value) => {
    if (key === 'requestShowing') {
      value = this.requestShowingRef.checked;
    } else if (key === 'requestVideoTour') {
      value = this.requestVideoTourRef.checked;
    }

    const newState = {
      ...this.state,
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    };

    if (newState.hasSubmitted) {
      newState.errors = this._validateFields(newState);
    }

    this.setState(newState);
  };

  /**
   * Do validation, shape data to be passed to `visitor.contactForm`, call it,
   * and update state.
   */
  handleSubmit() {
    const newState = {
      ...this.state,
      hasSubmitted: true,
      errors: this._validateFields(this.state),
    };

    if (newState.errors && newState.errors.length) {
      this.setState(newState);
      return;
    }

    const params = {
      type:
        newState.formData.requestShowing || newState.formData.requestVideoTour
          ? 'ScheduleShowing'
          : 'AskExpert',
      ListingID: this.props.listingID,
      firstName: newState.formData.firstName,
      lastName: newState.formData.lastName,
      phone: newState.formData.phone,
      email: newState.formData.email,
      token: newState.formData.token,
      comments: newState.formData.comments,
      visitorID: visitorModel.id,
      visitID: visitorModel.get('VisitID'),
      agentID: getAgentId(),
    };

    // CNS-6735
    if (newState.formData.requestShowing || newState.formData.requestVideoTour) {
      params.ShowDate = newState.formData.showingDate.format('MM.DD.YYYY');
      params.ShowTime = newState.formData.showingTime;
    }

    if (newState.formData.requestVideoTour) {
      params.comments = `Video Tour Request! Lead Comment: ${newState.formData.comments}`;
    } else if (newState.formData.requestShowing) {
      params.comments = `In Person Tour Request! Lead Comment: ${newState.formData.comments}`;
    }
    // GTM tracking all contact form submits
    const trackingParams = {
      form: this.state.formData.requestShowing ? 'reqashowingform' : 'contactusform',
      contacttype: visitorModel.updateContactType('lead'),
      formtype: 'email',
      event: 'contact-form-submit',
      isVideoTourRequest: this.state.formData.requestVideoTour,
      isTourInPerson: this.state.formData.requestShowing,
    };

    const fnSendContactForm = () => {
      visitorModel.contactForm(
        params,
        () => {
          // first fire GTM event, then update visitor model if needed.
          this._submitTrackingData(trackingParams);
          visitorModel.updateAfterRegistrableAction({ phone: params.phone });
          this._scrollAfterSubmit();

          this.setState((prevState) => ({
            ...prevState,
            statusPanel: {
              text:
                'Your Request Was Submitted Successfully. We will be in touch with you soon to address your specific needs.',
              type: 'success', // Yes, this should really be a constant... Or a className.
            },
            // It'd be nice if the `contactForm` function took a "finally" callback.
            isSubmitting: false,
            renderForm: false,
          }));
        },
        () => {
          this._scrollAfterSubmit();

          this.setState((prevState) => ({
            ...prevState,
            statusPanel: {
              text: 'Something went wrong.',
              type: 'error',
            },
            isSubmitting: false,
            renderForm: false,
          }));
        }
      );
    };

    fnSendContactForm();

    newState.isSubmitting = true;

    // These are enqueued, so only call once. If async, `setState()` takes a
    // second callback. param that will be invoked when the state transition
    // is complete.
    this.setState(newState);
  }

  /**
   * Scroll the page to show the alert that's displayed after form submission.
   */
  _scrollAfterSubmit() {
    let scrollOffset;
    const $el = $(this._el);
    if (globalUtility.MQ_Medium()) {
      // eslint-disable-line new-cap
      scrollOffset = $el.offset().top - $('.js-listing__header').outerHeight() - 15; // Global spacing
    } else {
      const mobileHeaderHeight = $('.bt-listing__header-cta--mobile').outerHeight();
      scrollOffset = $el.offset().top - mobileHeaderHeight * 1.5;
    }

    $('html, body').animate(
      {
        scrollTop: scrollOffset,
      },
      300
    );
  }

  /**
   * Update recaptcha token
   * @param  {string} value Recaptcha value
   */
  handleRecaptchaChange = (value) => {
    // Oy, this is jank mate.
    const newState = {
      ...this.state,
      formData: {
        ...this.state.formData,
        token: value,
      },
    };
    this.setState(newState);
  };

  /**
   * Update state when form fields' values change.
   * @param  {string} key The field's key on `this.state`
   * @param  {Event} e
   */
  handleFieldChange(key, e) {
    let value;
    if (key === 'showingDate') {
      value = e;
    } else {
      value = e.target.value;
    }

    const newState = {
      ...this.state,
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    };

    // If the user has attempted to submit the form once before, re-validate
    // and update state on each field change.
    if (newState.hasSubmitted) {
      // This depends on the _new state_!
      newState.errors = this._validateFields(newState);
    }

    this.setState(newState);
  }

  /**
   * Validate each of the form's fields according to rules defined in
   * `validationRules`.
   * @return {array|undefined} An array of error objects if invalid, undefined
   *     otherwise.
   */
  _validateFields(state) {
    const errors = [];
    const validationRules = getValidationRules(state);

    for (const fieldName of Object.keys(validationRules)) {
      if ({}.hasOwnProperty.call(validationRules, fieldName)) {
        const { isValid, message } = validationRules[fieldName];
        const value = state.formData[fieldName];
        if (!isValid(value)) {
          errors.push({ fieldName, message });
        }
      }
    }

    return errors;
  }

  render() {
    const {
      requestShowing,
      requestVideoTour,
      showingDate,
      showingTime,
      firstName,
      lastName,
      email,
      phone,
      comments,
      hiddenSpamField,
    } = this.state.formData;

    const dateAndTimeRow = (
      <Cell className="pt-4">
        <Grid gutters xs="full" md="halves">
          <Cell>
            <div className="js-datepicker bt-position--relative">
              <DatePicker
                id={this._fieldID('showingDate')}
                label="Date"
                value={showingDate}
                onChange={this.handleFieldChange.bind(this, 'showingDate')} // eslint-disable-line
                formGroupProps={{ className: 'js-showingDate' }}
                dataCY={PROP_DETAILS.SS_DATE}
              />
            </div>
          </Cell>
          <Cell>
            <Select
              label="Time of Day"
              id={this._fieldID('showingTime')}
              dataCY={PROP_DETAILS.SS_TIME}
              name={this._fieldID('showingTime')}
              value={showingTime}
              onChange={this.handleFieldChange.bind(this, 'showingTime')} // eslint-disable-line
              options={[
                { value: 'Anytime' },
                { value: 'Morning' },
                { value: 'Afternoon' },
                { value: 'Evening' },
              ]}
            />
          </Cell>
        </Grid>
      </Cell>
    );

    /**
     * Get the corresponding error object from our state.
     *
     * @note There are better ways of handling error state, but we'll refactor this component soon
     * hopefully anyway to a functional component. We can implement a better error state structure
     * at that time.
     *
     * @param {string} fieldName
     * @returns {{type:string, message:string}}
     */
    const getErrors = (fieldName) => {
      const { errors } = this.state;

      if (errors && errors.length) {
        const fieldError = errors.find((err) => err.fieldName === fieldName);

        if (fieldError) {
          return { type: fieldError.fieldName, message: fieldError.message };
        }

        return null;
      }

      return null;
    };

    const formComponent = (() => (
      <form>
        <Grid gutters resetVertical justifyContent="end" xs="full">
          {/* First and last name fields */}
          <Cell md={6}>
            <Input
              label="First Name"
              id={this._fieldID('firstName')}
              dataCY={PROP_DETAILS.SS_FIRST_NAME}
              className="at-firstName-txt"
              name={this._fieldID('firstName')}
              value={firstName}
              autoComplete="given-name"
              ref={this._returnFirstNameRef}
              onChange={this.handleFieldChange.bind(this, 'firstName')} // eslint-disable-line
              error={getErrors('firstName')}
              errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
              required
            />
          </Cell>
          <Cell md={6}>
            <Input
              label="Last Name"
              id={this._fieldID('lastName')}
              dataCY={PROP_DETAILS.SS_LAST_NAME}
              className="at-lastName-txt"
              name={this._fieldID('lastName')}
              type="text"
              autoComplete="family-name"
              required
              value={lastName}
              onChange={this.handleFieldChange.bind(this, 'lastName')} // eslint-disable-line
              error={getErrors('lastName')}
              errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
            />
          </Cell>

          {/* Email and phone fields */}
          <Cell md={6}>
            <Input
              label="Email"
              id={this._fieldID('email')}
              dataCY={PROP_DETAILS.SS_EMAIL}
              className="at-email-txt"
              name={this._fieldID('email')}
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={this.handleFieldChange.bind(this, 'email')} // eslint-disable-line
              error={getErrors('email')}
              errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
            />
          </Cell>
          <Cell md={6}>
            <Input
              label="Phone"
              id={this._fieldID('phone')}
              dataCY={PROP_DETAILS.SS_PHONE}
              className="at-phone-txt"
              type="text"
              data-parsley-phone
              autoComplete="tel"
              name={this._fieldID('phone')}
              required
              value={phone}
              onChange={this.handleFieldChange.bind(this, 'phone')} // eslint-disable-line
              error={getErrors('phone')}
              errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
            />
          </Cell>

          {/* Comments textarea and a spam protection hidden field */}
          <Cell>
            <TextArea
              label="What would you like to know?"
              id={this._fieldID('comments')}
              className="at-comment-txt"
              name={this._fieldID('comments')}
              ref={this._returnCommentsRef}
              value={comments}
              onChange={this.handleFieldChange.bind(this, 'comments')} // eslint-disable-line
              error={getErrors('comments')}
              errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
            />
          </Cell>

          {/* "I'm interested in seeing this property" Checkbox */}
          {/* With CNS-6735, these checkboxes have been updated to "Tour in Person"
          and "Tour via Video Chat" */}
          {!isSold(this.props.listing.Status) ? (
            <Cell>
              <Grid gutters resetVertical>
                <Cell xs="none">
                  <Checkbox
                    id="listing-details__request-showing"
                    label={
                      config.disableVideoTour
                        ? "I'm interested in seeing this property"
                        : 'Tour in Person'
                    }
                    name="requestShowing"
                    value="requestShowing"
                    checked={requestShowing}
                    disabled={requestVideoTour}
                    ref={this._returnRequestShowingRef}
                    onChange={() => this.handleCheckboxChange('requestShowing')}
                  />
                </Cell>
                {!config.disableVideoTour && (
                  <Cell xs="none">
                    <Checkbox
                      id="listing-details__request--video-tour"
                      label="Tour via Video Chat"
                      name="requestVideoTour"
                      value="requestVideoTour"
                      checked={requestVideoTour}
                      disabled={requestShowing}
                      ref={this._returnRequestVideoTourRef}
                      onChange={() => this.handleCheckboxChange('requestVideoTour')}
                    />
                  </Cell>
                )}
              </Grid>
            </Cell>
          ) : (
            ''
          )}

          {/* Recaptcha - Only renders when the Recaptcha Plugin is enabled */}
          {config.recaptchaEnabled && (
            <Cell sm={8} md={6}>
              <RecaptchaV2
                onChange={this.handleRecaptchaChange}
                alignRight
                error={getErrors('token')}
              />
            </Cell>
          )}

          {/* Date and time inputs for requesting a showing */}
          {requestShowing || requestVideoTour ? dateAndTimeRow : null}
          <Cell className="text-xs--right">
            <Grid gutters resetVertical xs="full" className="grid-xs--full">
              <Cell md={8}>
                <Input
                  id="contactFormSpamFilter"
                  type="text"
                  name="LastName"
                  className="LastName"
                  value={hiddenSpamField}
                  tabIndex="-1"
                  onChange={this.handleFieldChange.bind(this, 'hiddenSpamField')} // eslint-disable-line
                  aria-label="hidden field"
                  hidden
                />
              </Cell>
              <Cell md={4}>
                <PrimaryButton
                  type="button"
                  className="at-contact-form-submit at-submit-btn"
                  disabled={!!this.state.isSubmitting}
                  onClick={this.handleSubmit.bind(this)} // eslint-disable-line
                  width="full"
                  dataCY={PROP_DETAILS.SS_SUBMIT}
                >
                  {this.state.isSubmitting ? 'Sending...' : 'Submit'}
                </PrimaryButton>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </form>
    ))();

    /* eslint-disable */
    return (
      <div
        ref={(c) => {
          this._el = c;
        }}
      >
        {this.state.statusPanel && (
          <Alert type={this.state.statusPanel.type} dataCY={PROP_DETAILS.SS_ALERT}>
            {this.state.statusPanel.text}
          </Alert>
        )}
        {this.state.renderForm ? formComponent : null}
      </div>
    );
  }
}

ListingDetailsContactForm.displayName = 'ListingDetailsContactForm';
ListingDetailsContactForm.propTypes = {
  listingID: PropTypes.number.isRequired,
  listing: PropTypes.shape({
    Location: PropTypes.shape({
      FormattedAddress: PropTypes.string.isRequired,
    }).isRequired,
    Status: PropTypes.string,
  }).isRequired,
};

export default ListingDetailsContactForm;
