import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const Address = (props) => {
  const {
    rep,
    tenant,
    name,
    isLender
  } = props;

  const addressType = cx(
    'bt-listing__agent__brandid__address',
    {
      'at-agent-brandid-address': !isLender,
      'at-lender-brandid-address': isLender
    }
  );

  return (
    <address>
      <div className="bt-listing__agent__brandid__legal-name" itemProp="brand">{name}</div>
      <div itemType="http://schema.org/PostalAddress" itemScope itemProp="address">
        {
          tenant.isAgentSubdomain || isLender ? (
            <div>
              <div className={addressType} itemProp="streetAddress">
                {!isLender ? rep.Address1 : rep.Street}
              </div>
              <div className="bt-listing__agent__brandid__address">
                <span itemProp="addressLocality">{rep.City},</span>
                <span itemProp="addressRegion"> {rep.State}</span>
                <span itemProp="postalCode"> {rep.PostalCode}</span>
              </div>
            </div>
          ) : (
            <div>
              <div className={addressType} itemProp="streetAddress">
                {tenant.street}
              </div>
              <div className="bt-listing__agent__brandid__address">
                <span itemProp="addressLocality">{tenant.city},</span>
                <span itemProp="addressRegion"> {tenant.state}</span>
                <span itemProp="postalCode"> {tenant.zip}</span>
              </div>
            </div>
          )
        }
      </div>
    </address>
  );
};

Address.displayName = 'Address';
Address.propTypes = {
  name: PropTypes.string,
  rep: PropTypes.shape({
    Address1: PropTypes.string,
    Street: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  tenant: PropTypes.shape({
    isAgentSubdomain: PropTypes.bool.isRequired,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  isLender: PropTypes.bool
};

export default Address;
